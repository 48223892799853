import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import ShowMoreText from "react-show-more-text";
import { IconContext } from "react-icons";
import InfoIcon from "@mui/icons-material/Info";
import {
  FaAt,
  FaBookReader,
  FaBoxes,
  FaFile,
  FaFileCode,
  FaFileImage,
  FaFilePdf,
  FaFileVideo,
  FaFileWord,
  FaHashtag,
  FaMailBulk,
  FaMask,
} from "react-icons/fa";
import LineChart from "../TableUi/components/sections/Charts/LineChart";
import WordCloudd from "../TableUi/components/sections/wordCloud/WordCloud";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Stack, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Usernavstats from "../TableUi/components/Navbar/Usernavstats";
import {
  RollerAnimation,
  StatsErrorMsg,
  ErrorMsg,
} from "../TableUi/components/loader/Loader";
import TransparentContainer from "../TableUi/components/sections/Charts/TransparentContainer";
import { styles_background_img } from "../utils/Globals/global_functions";
import { handle_session_get } from "../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
import { date_fm, link_website } from "../Assets/globalfunctions";
import { handleOpenNewTab } from "../utils/Globals/global_functions";
import { chart_color_array } from "../utils/Globals/global_variables";

function Pagestats() {
  const token = handle_session_get("Token");
  const [channel_name, set_channel_name] = useState(null);
  const [channel_link, set_channel_link] = useState(null);
  const [channel_error, set_channel_error] = useState("");
  // Copying the states of redux
  const [audience, set_audience] = useState(null);
  const [average_view, set_average_view] = useState(null);
  const [customer_engagement, set_customer_engagement] = useState(null);
  const [total_views, set_total_views] = useState(null);
  const [calendar_stats, set_calendar_stats] = useState(null);
  const [calendar_value, set_calendar_value] = useState([]);
  const [calendar_date, set_calendar_date] = useState(null);
  const [forwarded_data, set_forwarded_data] = useState(null);
  const [total_forwarded_post, set_total_forwarded_post] = useState(null);
  const [tot_forwarded_post_percent, set_tot_forwarded_post_percent] =
    useState(null);
  const [forwarded_channels, set_forwarded_channels] = useState(null);
  const [show_forwarded_category_tooltip, set_show_forwarded_category_tooltip] =
    useState(false);
  const [forwarded_categories_name, set_forwarded_categories_name] =
    useState("");
  const [statsloading, set_statsloading] = useState(false);
  const [total_post, set_total_post] = useState(null);
  const [file_extension, set_file_extension] = useState(null);
  const [total_file_extension, set_total_file_extension] = useState(null);
  const [file_data, set_file_data] = useState(null);
  const [file_title, set_file_title] = useState([]);
  const [mention_data, set_mention_data] = useState(null);
  const [total_mention_post_percent, set_total_mention_post_percent] =
    useState(null);
  const [total_mention_post, set_total_mention_post] = useState(null);
  const [hash_data, set_hash_data] = useState(null);
  const [total_hash_post_percent, set_total_hash_post_percent] = useState(null);
  const [total_hash_post, set_total_hash_post] = useState(null);
  const [calendar_all_data, set_calendar_all_data] = useState(null);
  const [calendar_loading, set_calendar_loading] = useState(false);
  const [current_search_type, set_current_search_type] = useState();
  const [is_group, set_is_group] = useState("Channel");
  const [word_cloud, set_word_cloud] = useState({
    loading: false,
    error: "",
    words: [],
    relevance: [],
  });

  const [user_post_details, set_user_post_details] = useState({
    loading: false,
    error: "",
    chart: {},
    messages: {},
    show_tooltip: false,
    hovered_userid: "",
  });
  const [user_post_details_table, set_user_post_details_table] = useState([]);
  const [userid_color, set_userid_color] = useState({});
  // State for the Heatmap data
  // const [heatmap_data, set_heatmap_data] = useState({})
  // const [heatmap_interval, set_heatmap_interval] = useState("daily")
  // const [heatmap_status, set_heatmap_status] = useState({
  //     "loading": false,
  //     "error": "",
  //     "error_status": false
  // })

  // State for hourly Posts
  const [hourly_post_data, set_hourly_post_data] = useState([]);
  const [hourly_post_status, set_hourly_post_status] = useState({
    loading: false,
    error: "",
    error_status: false,
  });
  const executeOnClick = () => {};
  const [calendar_filter, set_calendar_filter] = useState("month");
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;

        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    try {
      if (bytes === 0) {
        return "0 Bytes";
      }
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    } catch {
      return "FIle Size Not available";
    }
  };

  const byte_checker = (val) => {
    try {
      if (val.includes("B")) {
        return val;
      }
      const new_val = formatBytes(val);

      return new_val;
    } catch {
      return "FIle Size Not available";
    }
  };

  const date_fm_filter = (dt) => {
    if (dt !== undefined) {
      const sp_dt = dt.split("-");
      const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;

      const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10

      const month = date.toLocaleString("default", { month: "long" });
      const mod_month = month.substring(0, 3);
      const ret_dt = `${sp_dt[2]} ${mod_month} ${sp_dt[0]}`;
      // let ret_dt = `${mod_month} ${sp_dt[0]}`
      return ret_dt;
    }
    return false;
  };
  const containsEncodedComponents = (x) => {
    try {
      return decodeURI(x);
    } catch {
      return x;
    }
  };

  function safeJSONParse(input) {
    try {
      const json_parse = JSON.parse(input);
      if (Array.isArray(json_parse)) {
        const subs = json_parse[0]["subscribers"];
        return subs;
      }
      return input;
    } catch (error) {
      return input;
    }
  }

  const get_channel_name = (channel, type) => {
    set_statsloading(true);
    const data = { qtext: channel, search_type: type };
    axios
      .post("https://dev.recordedtelegram.com/verify_channel", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response_info = res.data.data;
        const channel_name =
          response_info["channel name"] &&
          response_info["channel name"] !== "None"
            ? response_info["channel name"]
            : response_info["channel link"] &&
              response_info["channel link"] !== "None"
            ? response_info["channel link"]
            : response_info["channel id"];
        const search_type =
          response_info["channel name"] &&
          response_info["channel name"] !== "None"
            ? "channel name"
            : response_info["channel link"] &&
              response_info["channel link"] !== "None"
            ? "link"
            : "id";
        const detect_channel_or_group =
          response_info["is_group"] === "True" ? "Group" : "Channel";
        set_is_group(detect_channel_or_group);
        set_channel_name(channel_name);
        set_channel_link(response_info["channel link"]);
        set_current_search_type(search_type);
        get_audience(channel_name, search_type);
        get_file_extension(channel_name, search_type);
        get_hash_data(channel_name, search_type);
        get_recent_file_data(channel_name, search_type);
        get_calendar_stats(channel_name, search_type);
        get_calendar_data(channel_name, "month", search_type);
        get_forwarded_stats(channel_name, search_type);
        get_forwarded_channel_stats(channel_name, search_type);
        get_active_user_stats(channel_name, search_type);
        get_mention_data(channel_name, search_type);
        get_graph_data_hourly(
          channel_name,
          "channel",
          search_type === "id" ? "id" : null,
        );
        get_word_cloud(channel_name, search_type);
        set_channel_error("");
        set_statsloading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_channel_error(err.response.data.errormsg);
        set_statsloading(false);
      });
  };

  const get_word_cloud = (val, search_type) => {
    const data = { qtext: val, search_type };
    set_word_cloud((prev) => ({ ...prev, loading: true, error: "" }));
    axios
      .post("https://dev.recordedtelegram.com/word_cloud_generator_v2", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const words_response = res.data.data;
        const word_response_filter = [];
        for (let key in words_response) {
          if (key.length > 3) {
            word_response_filter.push({
              text: key,
              value: words_response[key],
            });
          }
        }
        const sort_word_response = word_response_filter.sort(
          (a, b) => b.value - a.value,
        );
        const split_word_response = sort_word_response.slice(0, 50);
        set_word_cloud((prev) => ({
          ...prev,
          loading: false,
          error: "",
          words: split_word_response,
          relevance: calculate_relevance_words(word_response_filter),
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_word_cloud((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.errormsg,
        }));
      });
  };
  // Function copying from the statsAction
  const get_audience = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/stats", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_statsloading(false);

        if (res.data !== "list index out of range") {
          let tot_count = null;
          try {
            tot_count = res.data.total_post.count;
          } catch {
            // Do nothing
          }
          set_audience(res.data.audience_info);
          set_average_view(res.data.average_view);
          set_customer_engagement(res.data.customer_engagement);
          set_total_views(res.data.total_views);
          set_total_post(tot_count);
        }
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
      });
  };

  const get_calendar_stats = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/group_date_stats", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_calendar_stats(res.data);
        set_statsloading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_calendar_stats(null);
      });
  };

  const get_file_extension = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/file_extension_search", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_statsloading(false);
        let new_res = [];
        let new_title = [];
        if (Array.isArray(res.data.data) === false) {
          new_res = Object.fromEntries(
            Object.entries(res.data.data).slice(0, 5),
          );
          new_title = Object.keys(new_res || {}).map((val) => {
            const str = val;
            const str2 = str.charAt(0).toUpperCase() + str.slice(1);
            return str2;
          });
        }

        set_file_extension(new_res);
        set_total_file_extension(res.data.total);
        set_file_title(new_title);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_file_extension({});
      });
  };

  const get_recent_file_data = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/file_post_filter", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_file_data(res.data);
        set_statsloading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_file_data([]);
        set_statsloading(false);
      });
  };

  const get_mention_data = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/mentions", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_mention_data(res.data.data);
        set_total_mention_post_percent(res.data.total_mention_post_percent);
        set_total_mention_post(res.data.total_mention_post);
        set_statsloading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_mention_data({});
        set_total_mention_post_percent([]);
      });
  };

  const get_hash_data = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/hash", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response = res.data.data;
        set_hash_data(response);
        set_total_hash_post_percent(res.data.total_hash_post_percent);
        set_total_hash_post(res.data.total_hash_post);
        set_statsloading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_hash_data({});
      });
  };
  const get_calendar_data = (
    val,
    filters,
    search_type = current_search_type,
  ) => {
    set_calendar_loading(true);

    const data = { qtext: val, dt_filter: filters, search_type };

    axios
      .post("https://dev.recordedtelegram.com/group_date", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_statsloading(false);

        const date_sort = res.data.sort(
          (a, b) => new Date(b.key_as_string) - new Date(a.key_as_string),
        );

        const values = date_sort.map((val) => val.doc_count);
        let date = date_sort.map((val) => date_fm_filter(val.key_as_string));
        if (filters === "year") {
          const new_date = date.map((val) => val.split(" ")[2]);
          date = new_date;
        }

        if (filters === "month") {
          set_calendar_value(values.slice(0, 12).reverse());
          const new_date = date.map(
            (val) => `${val.split(" ")[1]} ${val.split(" ")[2]}`,
          );
          set_calendar_date(new_date.slice(0, 12).reverse());
          set_calendar_all_data(date_sort);
        } else if (filters === "week") {
          set_calendar_value(values.slice(0, 8).reverse());
          set_calendar_date(date.slice(0, 8).reverse());
          set_calendar_all_data(date_sort);
        } else {
          set_calendar_value(values.slice(0, 7).reverse());
          set_calendar_date(date.slice(0, 7).reverse());
          set_calendar_all_data(date_sort);
        }
        set_calendar_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_calendar_loading(false);
        set_calendar_all_data([]);
      });
  };
  const get_forwarded_stats = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/forwaded_post", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTimeout(() => {
          set_statsloading(false);
        }, 1000);

        set_forwarded_data(res.data.forwaded_post);
        set_total_forwarded_post(res.data.tot_forwarded_post);
        set_tot_forwarded_post_percent(res.data.tot_forwarded_post_percent);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_forwarded_data([]);
      });
  };

  const get_forwarded_channel_stats = (val, search_type) => {
    const data = { qtext: val, search_type };
    axios
      .post("https://dev.recordedtelegram.com/forwaded_post_channel", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const filter_obj = {
          pie_chart: {},
          categories_channel: {},
        };
        for (let key in res.data) {
          const category_name = key.replaceAll("_", " ");
          filter_obj["pie_chart"] = {
            ...filter_obj["pie_chart"],
            [category_name]: res.data[key]["value"],
          };
          filter_obj["categories_channel"] = {
            ...filter_obj["categories_channel"],
            [category_name]: res.data[key],
          };
        }
        set_forwarded_channels(filter_obj);
        set_statsloading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_forwarded_channels(null);
      });
  };

  //   const totalSum = data.reduce((sum, item) => sum + item.value, 0);

  // // Calculate the relevance of the overall data
  // const overallRelevance = (totalSum / (data.length * 100)) * 100;

  const get_active_user_stats = (val, search_type) => {
    const data = { qtext: val, search_type };
    set_user_post_details((prev) => ({
      ...prev,
      loading: true,
      error: "",
    }));
    axios
      .post("https://dev.recordedtelegram.com/group_user_stats", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const filter_sample_chart = {};
        const filter_sample_posts = {};
        const filter_color = {};
        const filter_table = [];
        const all_response = res.data.filter(
          (item) =>
            item.userid && item.userid !== null && item.userid !== "None",
        );
        all_response.forEach((item, index) => {
          const user_identity =
            item.username && item.username.length > 0
              ? item.username
              : item.userid;
          filter_sample_chart[user_identity] = item["total_post"];
          filter_sample_posts[user_identity] = {
            user_posts: item["messages"].filter(
              (value) => value.message && value.message.length > 0,
            ),
            user_id: item.userid,
            user_name: item.username ?? "None",
          };

          filter_color[user_identity] = chart_color_array[index];
          item.messages.forEach((item) => {
            if (item.message && item.message.length > 0) {
              filter_table.push(item);
            }
            return;
          });
        });
        set_user_post_details((prev) => ({
          ...prev,
          loading: false,
          error: "",
          chart: filter_sample_chart,
          messages: filter_sample_posts,
        }));
        set_user_post_details_table(() =>
          filter_table.length > 0
            ? filter_table.sort((a, b) => new Date(b.date) - new Date(a.date))
            : [],
        );
        set_userid_color(filter_color);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_user_post_details((prev) => ({
          ...prev,
          loading: false,
          error: "No Data to Display",
        }));
      });
  };

  // COMMENT FUNCTION OF HEAT MAP

  // const get_graph_data = (channel, interval) => {
  //     let token = localStorage.getItem('Token');
  //     set_heatmap_status(prev => ({ ...prev, loading: true, error_status: false, error: '' }))
  //     let data = { 'qtext': channel, 'graph_interval': interval }
  //     axios.post('https://dev.recordedtelegram.com/graph-data', data, {
  //     axios.post('https://dev.recordedtelegram.com/graph-data', data, {
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Authorization": `Bearer ${token}`
  //         }
  //     })
  //         .then(res => {
  //             set_heatmap_data(res.data.data)
  //             set_statsloading(false)
  //             set_heatmap_status(prev => ({ ...prev, loading: false, error_status: false, error: '' }))
  //         }).catch(err => {
  //             try {
  //                 let errors = Object.keys(err.response.data)
  //                 let token_error_status = errors.includes('tokenerror')
  //                 if (token_error_status === true) {
  //                     let error_check = error_checker(true)
  //                     if (error_check === true) {
  //                         toast.warn("Your session has expired. Redirecting you to Login page.", {
  //                             position: toast.POSITION.TOP_CENTER,
  //                             autoClose: 2000,

  //                         })
  //                         setTimeout(() => {
  //                             sessionStorage.clear(); window.location.href = '/'
  //                         }, 2500)

  //                         return
  //                     }
  //                 } else {
  //                     set_statsloading(false);
  //                     set_heatmap_status(prev => ({ ...prev, loading: false, error_status: true, error: err.response.data.errormsg }))
  //                 }

  //             } catch {

  //             }

  //         })
  // }
  const get_graph_data_hourly = (channel, type, sub_type = null) => {
    set_hourly_post_status((prev) => ({ ...prev, loading: true }));
    const data = { qtext: channel, search_type: type, sub_type };
    axios
      .post("https://dev.recordedtelegram.com/hourly-data", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const res_obj = res.data;
        const time_obj = Object.fromEntries(
          Object.entries(res_obj).sort(
            (a, b) => Number(a[0].split(":")[0]) - Number(b[0].split(":")[0]),
          ),
        );
        if (Object.keys(time_obj).length > 0) {
          const chart_data = [];
          for (const key in time_obj) {
            const key_value = {
              x: key,
              y: time_obj[key],
            };
            chart_data.push(key_value);
          }
          set_hourly_post_data(chart_data);
        } else {
          set_hourly_post_data([]);
        }
        set_statsloading(false);
        set_hourly_post_status((prev) => ({
          ...prev,
          loading: false,
          error: "",
          error_status: false,
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_statsloading(false);
        set_hourly_post_status((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.error,
          error_status: true,
        }));
      });
  };

  useEffect(() => {
    if (token !== null) {
      const url = window.location.href.toString();
      const new_url = containsEncodedComponents(url);
      const url_split = new_url.split("&type=");
      const search_type = url_split[1];
      const channel_name = url_split[0].split("?")[1].split("page=")[1];
      get_channel_name(channel_name, search_type);

      // COMMENT HEATMAP FUNC
      // get_graph_data(channel_name, "daily")
    }
  }, []);
  const icon_returner = (val) => {
    if (val.toLowerCase() === "pdf") {
      return (
        <IconContext.Provider
          value={{
            size: 18,
            marginTop: "10px",
            marginLeft: "12px",
            color: "white",
          }}
        >
          <FaFilePdf />
        </IconContext.Provider>
      );
    }
    if (val.toLowerCase() === "docx") {
      return (
        <IconContext.Provider
          value={{
            size: 18,
            marginTop: "10px",
            marginLeft: "12px",
            color: "white",
          }}
        >
          <FaFileWord />
        </IconContext.Provider>
      );
    }
    if (
      val.toLowerCase() === "jpg" ||
      val.toLowerCase() === "jpeg" ||
      val.toLowerCase() === "png"
    ) {
      return (
        <IconContext.Provider
          value={{
            size: 18,
            marginTop: "10px",
            marginLeft: "12px",
            color: "white",
          }}
        >
          <FaFileImage />
        </IconContext.Provider>
      );
    }
    if (val.toLowerCase() === "mp4" || val.toLowerCase() === "mkv") {
      return (
        <IconContext.Provider
          value={{
            size: 18,
            marginTop: "10px",
            marginLeft: "12px",
            color: "white",
          }}
        >
          <FaFileVideo />
        </IconContext.Provider>
      );
    }
    if (
      val.toLowerCase() === "py" ||
      val.toLowerCase() === "php" ||
      val.toLowerCase() === "js" ||
      val.toLowerCase() === "java" ||
      val.toLowerCase() === "html" ||
      val.toLowerCase() === "css"
    ) {
      return (
        <IconContext.Provider
          value={{
            size: 18,
            marginTop: "10px",
            marginLeft: "12px",
            color: "white",
          }}
        >
          <FaFileCode />
        </IconContext.Provider>
      );
    }
    return (
      <IconContext.Provider
        value={{
          size: 18,
          marginTop: "10px",
          marginLeft: "12px",
          color: "white",
        }}
      >
        <FaFile />
        {/* <span className='icon-inside'>{`.${val.length > 3 ? val.substring(0, 3).toLowerCase() : val.toLowerCase()}`}</span> */}
      </IconContext.Provider>
    );
  };
  const forwaded_formatter = (val) => {
    if (val.forwardedfromchanid !== "None") {
      // return val['forwardedfromchanid']

      try {
        const my_obj = JSON.parse(val.forwardedfromchanid);
        if (typeof my_obj === "object") {
          return my_obj.forwaded_from;
        }
        throw new Error("Not an object");
      } catch {
        return val.forwardedfromchanid;
      }
    } else {
      return JSON.parse(val.forwardedfromchanid).forwaded_from;
    }
  };

  const split_channel_name = (channel) => {
    if (channel.includes("t.me")) {
      const channelName = channel.split("/");
      return channelName[channelName.length - 1];
    }
    return channel;
  };

  // Custom tooltip
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2b3c46",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const calculate_relevance_words = (words_array) => {
    try {
      const totalSum = words_array.reduce((sum, item) => sum + item.value, 0);
      const sort_array = [...words_array].sort((a, b) => b.value - a.value);
      const slice_array = sort_array.slice(0, 10);
      const filter_word_array = slice_array.map((item) => ({
        ...item,
        relevance: (item["value"] / totalSum) * 100,
      }));
      return filter_word_array;
    } catch {
      return [];
    }
  };

  const sort_obj_values = (obj = null) => {
    try {
      if (obj) {
        const response_array = Object.entries(obj).sort((a, b) => b[1] - a[1]);
        return response_array;
      } else return [];
    } catch {
      return [];
    }
  };
  // const handle_auto_individual_search = (query,channel_name, e) => {
  //   e.preventDefault();
  //   const url_state = {
  //     chart_name: `#${query}`,
  //     channel_list:channel_name,
  //     status: true,
  //   };
  //   localStorage.setItem("fetch_mode_5", JSON.stringify(url_state));
  //   window.open("/dashboard", "_blank");
  // };

  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <section>
        <Usernavstats />
        <div style={{ width: "97%", margin: "50px auto" }}>
          {statsloading ? (
            <RollerAnimation />
          ) : channel_error ? (
            <StatsErrorMsg error={channel_error} />
          ) : (
            <>
              <TransparentContainer
                container={
                  <div>
                    <div
                      style={{ background: "none", color: "#dcf3ff" }}
                      className="card-header-tab card-header main-tab"
                    >
                      <div
                        style={{ marginLeft: "35px" }}
                        className="card-header-title font-size-lg text-capitalize font-weight-normal"
                      >
                        {channel_name !== null ? (
                          channel_link && channel_link !== "None" ? (
                            <button
                              onClick={() => link_website(channel_link)}
                              className="channel_redirect_btn"
                            >
                              {split_channel_name(channel_name)}
                            </button>
                          ) : (
                            split_channel_name(channel_name)
                          )
                        ) : (
                          "..."
                        )}
                      </div>
                    </div>

                    <div
                      className="no-gutters row"
                      style={{ marginLeft: "35px" }}
                    >
                      <div className="col-sm-6 col-md-3 col-xl-3">
                        <div
                          style={{
                            padding: "1rem",
                            marginBottom: "0px",
                            boxShadow: "none",
                          }}
                          className="card no-shadow rm-border bg-transparent widget-chart text-left"
                        >
                          <div className="icon-wrapper rounded-circle">
                            <div
                              style={{ background: "#d92550" }}
                              className="icon-wrapper-bg opacity-10 "
                            />
                            <i style={{ zIndex: "10", marginLeft: "12px" }}>
                              <IconContext.Provider
                                value={{
                                  size: 30,
                                  marginTop: "10px",
                                  marginLeft: "12px",
                                  color: "white",
                                }}
                              >
                                <FaMailBulk />
                              </IconContext.Provider>
                            </i>
                          </div>

                          <div className="widget-chart-content">
                            <div className="widget-subheading">Total Post</div>
                            <div className="widget-numbers">
                              {total_post !== null ? total_post : "..."}
                            </div>
                          </div>
                        </div>

                        <div className="divider m-0 d-md-none d-sm-block" />
                      </div>
                      <div className="col-sm-6 col-md-3 col-xl-3">
                        <div
                          style={{
                            padding: "1rem",
                            marginBottom: "0px",
                            boxShadow: "none",
                          }}
                          className="card no-shadow rm-border bg-transparent widget-chart text-left"
                        >
                          <div className="icon-wrapper rounded-circle">
                            <div
                              style={{ background: "#58D68D" }}
                              className="icon-wrapper-bg opacity-10 "
                            />
                            <i style={{ zIndex: "10", marginLeft: "12px" }}>
                              <IconContext.Provider
                                value={{
                                  size: 30,
                                  marginTop: "10px",
                                  marginLeft: "12px",
                                  color: "white",
                                }}
                              >
                                <FaBoxes />
                              </IconContext.Provider>
                            </i>
                          </div>

                          <div className="widget-chart-content">
                            <div className="widget-subheading">
                              Total Post Views
                            </div>
                            <div className="widget-numbers">
                              {total_views !== null ? total_views : "..."}
                            </div>
                          </div>
                        </div>

                        <div className="divider m-0 d-md-none d-sm-block" />
                      </div>

                      <div className="col-sm-6 col-md-3 col-xl-3">
                        <div
                          style={{
                            padding: "1rem",
                            marginBottom: "0px",
                            boxShadow: "none",
                          }}
                          className="card no-shadow rm-border bg-transparent widget-chart text-left"
                        >
                          <div className="icon-wrapper rounded-circle">
                            <div
                              style={{ background: "#2980B9" }}
                              className="icon-wrapper-bg opacity-10 "
                            />
                            <i style={{ zIndex: "10", marginLeft: "12px" }}>
                              <IconContext.Provider
                                value={{
                                  size: 30,
                                  marginTop: "10px",
                                  marginLeft: "12px",
                                  color: "white",
                                }}
                              >
                                <FaBookReader />
                              </IconContext.Provider>
                            </i>
                          </div>
                          <div className="widget-chart-content">
                            <div className="widget-subheading">
                              Average Post Views
                            </div>
                            <div className="widget-numbers">
                              {average_view != null
                                ? average_view.toFixed(2)
                                : "..."}
                            </div>
                          </div>
                        </div>

                        <div className="divider m-0 d-md-none d-sm-block" />
                      </div>
                      <div className="col-sm-6 col-md-3 col-xl-3">
                        <div
                          style={{
                            padding: "1rem",
                            marginBottom: "0px",
                            boxShadow: "none",
                          }}
                          className="card no-shadow rm-border bg-transparent widget-chart text-left"
                        >
                          <div className="icon-wrapper rounded-circle">
                            <div
                              style={{ background: "#34495E" }}
                              className="icon-wrapper-bg opacity-10 "
                            />
                            <i style={{ zIndex: "10", marginLeft: "12px" }}>
                              <IconContext.Provider
                                value={{
                                  size: 30,
                                  marginTop: "10px",
                                  color: "white",
                                }}
                              >
                                <FaMask />
                              </IconContext.Provider>
                            </i>
                          </div>

                          <div className="widget-chart-content">
                            <div className="widget-subheading">
                              Subscriber Engagement Per Post
                            </div>
                            <div className="widget-numbers">
                              {customer_engagement != null
                                ? `${customer_engagement.toFixed(2)}%`
                                : "..."}
                            </div>
                          </div>
                        </div>

                        <div className="divider m-0 d-md-none d-sm-block" />
                      </div>
                    </div>
                  </div>
                }
              />
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-6 col-xl-3">
                  <div
                    className="card mb-3 widget-content bg-night-fade"
                    style={{
                      backgroundImage:
                        "linear-gradient(to top, #3b76ef 0%, #78c1ff 100%)",
                      padding: "1rem",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Subscribers</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers text-white">
                          <span>
                            {audience !== null
                              ? safeJSONParse(audience.subs)
                              : "..."}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div
                    className="card mb-3 widget-content bg-night-fade"
                    style={{
                      backgroundImage:
                        "radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%)",
                      padding: "1rem",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Files</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers text-white">
                          <span>
                            {audience !== null ? audience.files : "..."}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div
                    className="card mb-3 widget-content bg-night-fade"
                    style={{
                      backgroundImage:
                        "radial-gradient(circle 248px at center, #EC6D10 0%, #E73927 47%, #CF0107 100%)",
                      padding: "1rem",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Links</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers text-white">
                          <span>
                            {audience !== null ? audience.links : "..."}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div
                    className="card mb-3 widget-content bg-night-fade"
                    style={{
                      backgroundImage:
                        "radial-gradient(circle 248px at center, #3DC8B8 0%, #23AE9E 47%, #0A9585 100%)",
                      padding: "1rem",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Media</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers text-white">
                          <span>
                            {audience !== null ? audience.media : "..."}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* NOTE */}
              {/* COMMENT HEAT MAP */}
              {/* <div className='heatmap_graph_container_main'>
                                    <div className='card-header' style={{ background: '#2b3c46', color: 'white' }}>
                                        <div className='card-title' style={{ background: '#2b3c46', color: 'white', textTransform: 'capitalize' }}>
                                            <span style={{ fontSize: '20px' }}>Channel/Group Posts Heatmap</span>
                                        </div>
                                    </div>
                                    {heatmap_status.loading === true ?
                                        <div className='card-body scroll-custom' style={{ height: '220px' }} > <div className="blocks list-tab">
                                            <div className="block orange" />
                                            <div className="block blue" />
                                        </div>
                                            <h6 style={{ marginTop: '-25px', color: "white" }}>Data may take time to load</h6>
                                        </div>
                                        :
                                        <div className='heatmap_graph_container_div'>
                                            {heatmap_status.error_status === true && heatmap_status.error ?
                                                <div className='card-body' style={{ height: '450px' }}>
                                                    <h2 style={{ marginTop: '50px', color: "white" }}>No Data to Display</h2>
                                                </div>
                                                :
                                                <div className={heatmap_interval === "monthly" ? "heatmap_graph_container_expand" : 'heatmap_graph_container'}>
                                                    <div className='heatmap_graph_div'>
                                                        {Object.keys(heatmap_data).length > 0 ?
                                                            Object.keys(heatmap_data).map((item, id) => {
                                                                const count_array = heatmap_data[item].map((num) => num.count)
                                                                const MAX_COUNT = Math.max(...count_array);
                                                                return (
                                                                    <Stack sx={{ position: 'relative', flex: 1 }}>
                                                                        <div className='heatmap_graph_chart' key={id}>
                                                                            {heatmap_data[item].map((val, id) => {
                                                                                const opacity = MAX_COUNT > 0 ? val.count / MAX_COUNT : 0; // Assuming MAX_COUNT is the maximum count value
                                                                                const brightness = 3; // Increase the brightness value for a brighter green
                                                                                const red = 0;
                                                                                const green = Math.round(128 * brightness); // Increase the green value
                                                                                const blue = 0;
                                                                                const backgroundColor = `rgba(${red}, ${green}, ${blue}, ${opacity <= 0.1 ? 0.2 : opacity})`; // Brighter green color with opacity

                                                                                return (
                                                                                    <Tooltip title={handle_tooltip_date(val.time, val.count, heatmap_interval)} placement="top" disableInteractive arrow
                                                                                        PopperProps={{
                                                                                            modifiers: [
                                                                                                {
                                                                                                    name: 'offset',
                                                                                                    options: {
                                                                                                        offset: [0, -10], // Adjust the values as needed to move the tooltip box up or down
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                        }}
                                                                                    >
                                                                                        <div className='heatmap_graph_chart_box' style={{ backgroundColor }} key={id} />
                                                                                    </Tooltip>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <div className='heatmap_graph_chart_date' key={id}>
                                                                            <p>{date_fm_str(item, heatmap_interval)}</p>
                                                                        </div>
                                                                    </Stack>
                                                                )
                                                            })
                                                            :
                                                            <div className='card-body' style={{ height: '450px' }}>
                                                                <h2 style={{ marginTop: '50px', color: 'white' }}>No Data to Display</h2>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className='heatmap_graph_interval_btn_div'>
                                                <button disabled={heatmap_interval === "daily" ? true : false} className={heatmap_interval === "daily" ? "heatmap_graph_interval_btn_active" : "heatmap_graph_interval_btn"} onClick={() => { get_graph_data(channel_name, "daily"); set_heatmap_interval("daily") }}>Daily</button>
                                                <button disabled={heatmap_interval === "monthly" ? true : false} className={heatmap_interval === "monthly" ? "heatmap_graph_interval_btn_active" : "heatmap_graph_interval_btn"} onClick={() => { get_graph_data(channel_name, "monthly"); set_heatmap_interval("monthly") }}>Monthly</button>
                                                <button disabled={heatmap_interval === "yearly" ? true : false} className={heatmap_interval === "yearly" ? "heatmap_graph_interval_btn_active" : "heatmap_graph_interval_btn"} onClick={() => { get_graph_data(channel_name, "yearly"); set_heatmap_interval("yearly") }}>Yearly</button>
                                            </div>
                                        </div>
                                    }
                                </div> */}

              <div className="channel_wordcloud_container  mb-4">
                <TransparentContainer
                  container={
                    word_cloud.loading === true ? (
                      <div
                        className="card-body scroll-custom"
                        style={{ height: "450px" }}
                      >
                        <div className="blocks list-tab">
                          <div className="block orange" />
                          <div className="block blue" />
                        </div>
                        <h6 style={{ marginTop: "-25px", color: "white" }}>
                          Data may take time to load
                        </h6>
                      </div>
                    ) : word_cloud.error ? (
                      <Box sx={{ height: "450px" }}>
                        <ErrorMsg error={word_cloud.error} />
                      </Box>
                    ) : (
                      <div className="row">
                        <div className="col-sm-7">
                          <WordCloudd word_cloud={word_cloud} />
                        </div>
                        <div className="col-sm-5 channel_worcloud_progressbar_container">
                          <div className="channel_wordcloud_divider" />
                          <div className="word_cloud_progressbar_chart_container scroll-custom ">
                            {word_cloud.relevance.map((item, index) => {
                              const max_value =
                                word_cloud.relevance[0].value ?? 100;
                              return (
                                <div key={index}>
                                  <h5 className="word_cloud_progressbar_heading">
                                    {item.text}
                                  </h5>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{ width: "65%", margin: "auto" }}
                                  >
                                    <p>{item.value} times</p>
                                    <p>
                                      Relevance :{" "}
                                      <span
                                        style={{
                                          color: "#48db6c",
                                          fontWeight: "bold",
                                        }}
                                      >{`${item.relevance.toFixed(2)}%`}</span>
                                    </p>
                                  </Stack>
                                  <progress
                                    id="progressbar"
                                    value={item.value}
                                    max={max_value + 200}
                                    style={{ width: "65%" }}
                                  />
                                  <div className="channel_wordcloud_divider_horizontal" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  }
                  heading={"channel Word Cloud"}
                />
              </div>
              <LineChart
                chart_status={hourly_post_status}
                chart_data={hourly_post_data}
                chart_type="channel"
              />
              <div className="row mt-4">
                <div className="col-sm-6 col-md-6">
                  <div className="row">
                    <div className="col-md-6 col-lg-3 col-xl-6">
                      <TransparentContainer
                        container={
                          <div className="post_date_no_container">
                            <h3 className="stats_title_color">
                              {calendar_stats !== null
                                ? calendar_stats.new_day.doc_count
                                : "..."}
                            </h3>
                            <h6 className="widget-subheading mb-0">
                              Max. No. of posts reached on day{" "}
                              <span
                                className="opacity-1"
                                style={{ fontSize: "1rem", display: "block" }}
                              >
                                {calendar_stats !== null
                                  ? `(${calendar_stats.new_day.key_as_string})`
                                  : "..."}
                              </span>
                            </h6>
                          </div>
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-6">
                      <TransparentContainer
                        container={
                          <div className="post_date_no_container">
                            <h3 className="stats_title_color">
                              {calendar_stats !== null
                                ? calendar_stats.new_week.doc_count
                                : "..."}
                            </h3>
                            <h6 className="widget-subheading mb-0">
                              Max. No. of posts reached on Week{" "}
                              <span
                                className="opacity-1"
                                style={{ fontSize: "1rem", display: "block" }}
                              >
                                {calendar_stats !== null
                                  ? `(${calendar_stats.new_week.key_as_string})`
                                  : "..."}
                              </span>
                            </h6>
                          </div>
                        }
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-6 mt-4">
                      <TransparentContainer
                        container={
                          <div className="post_date_no_container">
                            <h3 className="stats_title_color">
                              {calendar_stats !== null
                                ? calendar_stats.new_month.doc_count
                                : "..."}
                            </h3>
                            <h6 className="widget-subheading mb-0">
                              Max. No. of posts reached on Month{" "}
                              <span
                                className="opacity-1"
                                style={{ fontSize: "1rem", display: "block" }}
                              >
                                {calendar_stats !== null
                                  ? `(${calendar_stats.new_month.key_as_string})`
                                  : "..."}
                              </span>{" "}
                            </h6>
                          </div>
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-6 mt-4">
                      <TransparentContainer
                        container={
                          <div className="post_date_no_container">
                            <h3 className="stats_title_color">
                              {calendar_stats !== null
                                ? calendar_stats.new_year.doc_count
                                : "..."}
                            </h3>
                            <h6 className="widget-subheading mb-0">
                              Max. No. of posts reached on Year{" "}
                              <span
                                className="opacity-1"
                                style={{ fontSize: "1rem", display: "block" }}
                              >
                                {calendar_stats !== null
                                  ? `(${calendar_stats.new_year.key_as_string})`
                                  : "..."}
                              </span>{" "}
                            </h6>
                          </div>
                        }
                      />
                    </div>
                    {/* From Here */}
                    <div className="col-md-6 col-lg-3 col-xl-6 mt-4">
                      <TransparentContainer
                        container={
                          <div className="widget-content">
                            <div className="widget-content-outer">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    Total Forwaded post
                                  </div>
                                  <div className="widget-subheading" />
                                </div>
                                <div className="widget-content-right">
                                  <div className="widget-numbers text-success">
                                    {total_forwarded_post !== null
                                      ? total_forwarded_post
                                      : "..."}
                                  </div>
                                </div>
                              </div>

                              <div className="widget-progress-wrapper">
                                <div className="progress-bar-sm progress">
                                  <div
                                    className="progress-bar "
                                    role="progressbar"
                                    aria-valuenow="71"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={
                                      tot_forwarded_post_percent !== null
                                        ? {
                                            width: `${tot_forwarded_post_percent}%`,
                                            background: "rgb(252, 75, 108)",
                                          }
                                        : {
                                            width: "71%",
                                            background: "rgb(252, 75, 108)",
                                          }
                                    }
                                  />
                                </div>
                                <div className="progress-sub-label">
                                  <div className="rightpercent">
                                    {tot_forwarded_post_percent !== null
                                      ? `${parseFloat(
                                          tot_forwarded_post_percent,
                                        ).toFixed(2)}%`
                                      : "..."}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="" />
                          </div>
                        }
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 col-xl-6 mt-4">
                      <TransparentContainer
                        container={
                          <div className="widget-content">
                            <div className="widget-content-outer">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    Total Forwarded channel
                                  </div>
                                  <div className="widget-subheading" />
                                </div>
                                <div className="widget-content-right">
                                  <div className="widget-numbers text-success">
                                    {forwarded_channels !== null
                                      ? Object.keys(forwarded_channels).length
                                      : "..."}
                                  </div>
                                </div>
                              </div>

                              <div className="widget-progress-wrapper">
                                <div className="progress-bar-sm progress">
                                  <div
                                    className="progress-bar "
                                    role="progressbar"
                                    aria-valuenow="71"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={
                                      forwarded_channels !== null
                                        ? {
                                            width: `${
                                              Object.keys(forwarded_channels)
                                                .length
                                            }%`,
                                            background: "rgb(38, 198, 218)",
                                          }
                                        : {
                                            width: "71%",
                                            background: "rgb(38, 198, 218)",
                                          }
                                    }
                                  />
                                </div>
                                <div className="progress-sub-label">
                                  <div className="rightpercent">
                                    {forwarded_channels !== null
                                      ? Object.keys(forwarded_channels).length
                                      : "..."}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="" />
                          </div>
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-6 mt-4">
                      <TransparentContainer
                        container={
                          <div className="widget-content">
                            <div className="widget-content-outer">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    Total Replied post
                                  </div>
                                  <div className="widget-subheading" />
                                </div>
                                <div className="widget-content-right">
                                  <div className="widget-numbers text-success">
                                    0
                                  </div>
                                </div>
                              </div>

                              <div className="widget-progress-wrapper">
                                <div className="progress-bar-sm progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="71"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                      width: "0%",
                                      background: "#da624a",
                                    }}
                                  />
                                </div>
                                <div className="progress-sub-label">
                                  <div className="rightpercent">0%</div>
                                </div>
                              </div>
                            </div>

                            <div className="" />
                          </div>
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-6 mt-4">
                      <TransparentContainer
                        container={
                          <div className="widget-content">
                            <div className="widget-content-outer">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    Total Replied User
                                  </div>
                                  <div className="widget-subheading" />
                                </div>
                                <div className="widget-content-right">
                                  <div className="widget-numbers text-success">
                                    0
                                  </div>
                                </div>
                              </div>

                              <div className="widget-progress-wrapper">
                                <div className="progress-bar-sm progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="71"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                      width: "0%",
                                      background: "rgb(0, 194, 146)",
                                    }}
                                  />
                                </div>
                                <div className="progress-sub-label">
                                  <div className="rightpercent">0</div>
                                </div>
                              </div>
                            </div>

                            <div className="" />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-6">
                  <TransparentContainer
                    heading={`${calendar_filter} Post Update`}
                    container={
                      <div>
                        <div className="widget-chart p-0">
                          {calendar_loading ? (
                            <div
                              className="card-body scroll-custom"
                              style={{ height: "450px" }}
                            >
                              {" "}
                              <div className="blocks list-tab">
                                <div className="block orange" />
                                <div className="block blue" />
                              </div>
                              <h6 style={{ marginTop: "-25px" }}>
                                Data may take time to load
                              </h6>
                            </div>
                          ) : calendar_all_data !== null &&
                            calendar_all_data.length === 0 ? (
                            <div
                              className="card-body card_align_center"
                              style={{ height: "450px" }}
                            >
                              <h2>No Data to Display</h2>
                            </div>
                          ) : (
                            <div style={{ minHeight: "450px" }}>
                              <Chart
                                options={{
                                  chart: {
                                    height: 350,
                                    type: "line",
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  stroke: {
                                    curve: "smooth",
                                    width: 2,
                                  },
                                  plotOptions: {
                                    bar: {
                                      borderRadius: 10,
                                      dataLabels: {
                                        position: "top", // top, center, bottom
                                      },
                                    },
                                  },
                                  grid: { show: false },
                                  xaxis: {
                                    categories: calendar_date,
                                    labels: {
                                      style: {
                                        fontSize: "11px",
                                        fontFamily: "monospace",
                                        fontWeight: 400,
                                        colors: [
                                          "#FF7EA5",
                                          "#00FA9A",
                                          "#4EE2EC",
                                          "#C388F6",
                                          "#FF7EA5",
                                          "#00FA9A",
                                          "#4EE2EC",
                                          "#C388F6",
                                          "#FF7EA5",
                                          "#00FA9A",
                                          "#4EE2EC",
                                          "#C388F6",
                                        ],
                                      },
                                    },
                                    position: "top",
                                    axisBorder: {
                                      show: false,
                                    },
                                    axisTicks: {
                                      show: false,
                                    },
                                    crosshairs: {
                                      fill: {
                                        type: "gradient",
                                        gradient: {
                                          colorFrom: "#16d9e3",
                                          colorTo: "#BED1E6",
                                          stops: [0, 100],
                                          opacityFrom: 0.4,
                                          opacityTo: 0.5,
                                        },
                                      },
                                    },
                                    tooltip: {
                                      enabled: true,
                                    },
                                  },
                                  colors:
                                    calendar_filter === "week"
                                      ? ["#16d9e3"]
                                      : calendar_filter === "month"
                                      ? ["#EC6D10"]
                                      : calendar_filter === "year"
                                      ? ["#3ac47d"]
                                      : ["#78c1ff"],
                                  yaxis: {
                                    axisBorder: {
                                      show: false,
                                    },
                                    axisTicks: {
                                      show: false,
                                    },
                                    labels: {
                                      show: false,
                                      formatter(val) {
                                        return `${val}Post`;
                                      },
                                    },
                                  },
                                }}
                                series={[
                                  {
                                    name: calendar_filter,
                                    data: calendar_value,
                                  },
                                ]}
                                type="line"
                                height={450}
                              />
                            </div>
                          )}
                        </div>
                        <div className="divider mb-0" />
                        <div className="grid-menu grid-menu-2col">
                          <div className="no-gutters row">
                            <div className="p-2 col-sm-3">
                              <button
                                style={
                                  calendar_filter === "day"
                                    ? { background: "#78c1ff", color: " #fff" }
                                    : {
                                        color: "#78c1ff",
                                        borderColor: " #78c1ff",
                                      }
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  set_calendar_filter("day");
                                  get_calendar_data(channel_name, "day");
                                }}
                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-one"
                              >
                                {" "}
                                Day
                              </button>
                            </div>
                            <div className="p-2 col-sm-3">
                              <button
                                style={
                                  calendar_filter === "week"
                                    ? { background: "#16d9e3", color: " #fff" }
                                    : {
                                        color: "#16d9e3",
                                        borderColor: " #16d9e3",
                                      }
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  set_calendar_filter("week");
                                  get_calendar_data(channel_name, "week");
                                }}
                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-two"
                              >
                                {" "}
                                Weekly
                              </button>
                            </div>
                            <div className="p-2 col-sm-3">
                              <button
                                style={
                                  calendar_filter === "month"
                                    ? { background: "#EC6D10", color: " #fff" }
                                    : {
                                        color: "#EC6D10",
                                        borderColor: " #EC6D10",
                                      }
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  set_calendar_filter("month");
                                  get_calendar_data(channel_name, "month");
                                }}
                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-three"
                              >
                                {" "}
                                Monthly
                              </button>
                            </div>
                            <div className="p-2 col-sm-3">
                              <button
                                style={
                                  calendar_filter === "year"
                                    ? { background: "#3ac47d", color: " #fff" }
                                    : {
                                        color: "#3ac47d",
                                        borderColor: " #3ac47d",
                                      }
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  set_calendar_filter("year");
                                  get_calendar_data(channel_name, "year");
                                }}
                                className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-four"
                              >
                                {" "}
                                Yearly
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-md-3 col-xl-3 col-sm-3">
                  <div className="card mb-3 widget-content dk-mode">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Total Forwaded post
                          </div>
                          <div className="widget-subheading" />
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-success">
                            {total_forwarded_post !== null
                              ? total_forwarded_post
                              : "..."}
                          </div>
                        </div>
                      </div>

                      <div className="widget-progress-wrapper">
                        <div className="progress-bar-sm progress">
                          <div
                            className="progress-bar "
                            role="progressbar"
                            aria-valuenow="71"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={
                              tot_forwarded_post_percent !== null
                                ? {
                                    width: `${tot_forwarded_post_percent}%`,
                                    background: "rgb(252, 75, 108)",
                                  }
                                : {
                                    width: "71%",
                                    background: "rgb(252, 75, 108)",
                                  }
                            }
                          />
                        </div>
                        <div className="progress-sub-label">
                          <div className="rightpercent">
                            {tot_forwarded_post_percent !== null
                              ? `${parseFloat(
                                  tot_forwarded_post_percent,
                                ).toFixed(2)}%`
                              : "..."}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="" />
                  </div>
                </div>
                <div className="col-md-3 col-xl-3">
                  <div className="card mb-3 widget-content dk-mode">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Total Forwarded channel
                          </div>
                          <div className="widget-subheading" />
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-success">
                            {forwarded_channels !== null
                              ? Object.keys(forwarded_channels).length
                              : "..."}
                          </div>
                        </div>
                      </div>

                      <div className="widget-progress-wrapper">
                        <div className="progress-bar-sm progress">
                          <div
                            className="progress-bar "
                            role="progressbar"
                            aria-valuenow="71"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={
                              forwarded_channels !== null
                                ? {
                                    width: `${
                                      Object.keys(forwarded_channels).length
                                    }%`,
                                    background: "rgb(38, 198, 218)",
                                  }
                                : {
                                    width: "71%",
                                    background: "rgb(38, 198, 218)",
                                  }
                            }
                          />
                        </div>
                        <div className="progress-sub-label">
                          <div className="rightpercent">
                            {forwarded_channels !== null
                              ? Object.keys(forwarded_channels).length
                              : "..."}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="" />
                  </div>
                </div>
                <div className="col-md-3 col-xl-3">
                  <div className="card mb-3 widget-content dk-mode">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Total Replied post
                          </div>
                          <div className="widget-subheading" />
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-success">0</div>
                        </div>
                      </div>

                      <div className="widget-progress-wrapper">
                        <div className="progress-bar-sm progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="71"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "0%", background: "#da624a" }}
                          />
                        </div>
                        <div className="progress-sub-label">
                          <div className="rightpercent">0%</div>
                        </div>
                      </div>
                    </div>

                    <div className="" />
                  </div>
                </div>
                <div className="col-md-3 col-xl-3">
                  <div className="card mb-3 widget-content dk-mode">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Total Replied User
                          </div>
                          <div className="widget-subheading" />
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-success">0</div>
                        </div>
                      </div>

                      <div className="widget-progress-wrapper">
                        <div className="progress-bar-sm progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="71"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: "0%",
                              background: "rgb(0, 194, 146)",
                            }}
                          />
                        </div>
                        <div className="progress-sub-label">
                          <div className="rightpercent">0</div>
                        </div>
                      </div>
                    </div>

                    <div className="" />
                  </div>
                </div>
              </div> */}
              {/* Note user posts */}
              {is_group === "Group" ? (
                <div className="row mt-4">
                  <div className="col-md-offset-1 col-md-8">
                    <TransparentContainer
                      heading={"Recent Messages"}
                      container={
                        <div
                          className="card-body"
                          style={{ maxHeight: "550px" }}
                        >
                          <div className="table-wrap scroll-custom">
                            {user_post_details.loading === false ? (
                              user_post_details.error ||
                              user_post_details_table.length === 0 ? (
                                <div
                                  className="card-body card_align_center"
                                  style={{ height: "350px" }}
                                >
                                  <h2>No Data to Display</h2>
                                </div>
                              ) : (
                                <table className="table">
                                  <thead className="thead-primary">
                                    <tr>
                                      <th>#</th>
                                      <th>Message</th>
                                      <th>User</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {user_post_details_table.map((val, i) => {
                                      const user_identity =
                                        val.username && val.username.length > 0
                                          ? val.username
                                          : val.id;
                                      return (
                                        <tr key={i}>
                                          <td>{i}</td>
                                          <td
                                            style={{
                                              width: "500px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {val.message ? (
                                              val.message.length > 0 ? (
                                                <ShowMoreText
                                                  lines={2}
                                                  more="Show more"
                                                  less="Show less"
                                                  onClick={executeOnClick}
                                                  expanded={false}
                                                  width={500}
                                                  truncatedEndingComponent="... "
                                                  className="content-css"
                                                >
                                                  {val.message}
                                                </ShowMoreText>
                                              ) : (
                                                "Message not available"
                                              )
                                            ) : (
                                              "Message not available"
                                            )}
                                          </td>
                                          <td>
                                            {" "}
                                            <span
                                              onClick={(event) =>
                                                handleOpenNewTab(
                                                  `/users?page=${user_post_details["messages"][user_identity]["user_id"]}`,
                                                  event,
                                                )
                                              }
                                              className="channel_stats_recent_messages_table_username"
                                              style={{
                                                background:
                                                  userid_color[user_identity],
                                              }}
                                            >
                                              {user_identity}
                                            </span>
                                          </td>
                                          <td className="text-nowrap">
                                            {date_fm(val.date)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              )
                            ) : (
                              <div
                                className="card-body scroll-custom"
                                style={{ height: "325px" }}
                              >
                                {" "}
                                <div className="blocks list-tab">
                                  <div className="block orange" />
                                  <div className="block blue" />
                                </div>
                                <h6 style={{ marginTop: "-25px" }}>
                                  Data may take time to load
                                </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                    />
                  </div>
                  <div className="col-md-offset-1 col-md-4">
                    <TransparentContainer
                      container={
                        <div>
                          <div
                            className="card-header"
                            style={{ color: "white" }}
                          >
                            <div
                              className="stats_title_color"
                              style={{
                                background: "none",
                                textTransform: "capitalize",
                              }}
                            >
                              <span style={{ fontSize: "20px" }}>
                                Top Active Users{" "}
                                {/* {Object.keys(user_post_details["chart"]).length > 0 ? (
                                  <HtmlTooltip title="This pie chart represents percentage of forwarded posts from each category.">
                                    <InfoIcon
                                      sx={{ color: "#b3a800", marginLeft: "4px" }}
                                    />
                                  </HtmlTooltip>
                                ) : null} */}
                              </span>
                            </div>
                          </div>
                          <div
                            className="card-body"
                            style={{ maxHeight: "550px" }}
                          >
                            <div className="table-wrap scroll-custom">
                              {user_post_details.loading === false ? (
                                user_post_details.error ||
                                Object.keys(user_post_details["chart"])
                                  .length === 0 ? (
                                  <div
                                    className="card-body card_align_center"
                                    style={{ height: "350px" }}
                                  >
                                    <h2>No Data to Display</h2>
                                  </div>
                                ) : (
                                  <div className="channel_stats_forwarded_channels channel_stats_top_active_users">
                                    <Chart
                                      options={{
                                        chart: {
                                          type: "pie",
                                          height: 350,
                                          events: {
                                            legendClick: function (
                                              chartContext,
                                              seriesIndex,
                                              config,
                                            ) {
                                              handleOpenNewTab(
                                                `/users?page=${
                                                  user_post_details["messages"][
                                                    config.config.labels[
                                                      seriesIndex
                                                    ]
                                                  ]["user_id"]
                                                }`,
                                              );
                                            },
                                          },
                                        },

                                        stroke: {
                                          show: false,
                                        },
                                        legend: {
                                          position: "bottom",
                                          labels: {
                                            colors: "#fff",
                                          },
                                        },
                                        tooltip: {
                                          custom: function ({
                                            seriesIndex,
                                            w,
                                          }) {
                                            set_user_post_details((prev) => {
                                              if (
                                                prev.hovered_userid ===
                                                w.config.labels[seriesIndex]
                                              ) {
                                                return prev;
                                              }
                                              return {
                                                ...prev,
                                                show_tooltip: true,
                                                hovered_userid:
                                                  w["config"]["labels"][
                                                    seriesIndex
                                                  ],
                                              };
                                            });

                                            return null;
                                          },
                                        },
                                        labels: Object.keys(
                                          user_post_details["chart"],
                                        ),
                                        colors:
                                          Object.keys(
                                            user_post_details["chart"],
                                          ).length > 0 &&
                                          Object.keys(userid_color).length > 0
                                            ? Object.keys(
                                                user_post_details["chart"],
                                              ).map(
                                                (userid) =>
                                                  userid_color[userid],
                                              )
                                            : [],
                                        responsive: [
                                          {
                                            breakpoint: 480,
                                            options: {
                                              chart: {
                                                width: 200,
                                              },
                                              legend: {
                                                position: "bottom",
                                              },
                                            },
                                          },
                                        ],
                                      }}
                                      series={Object.values(
                                        user_post_details["chart"] || {},
                                      )}
                                      type="pie"
                                      height={350}
                                    />
                                    {user_post_details["show_tooltip"] ===
                                    true ? (
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          set_user_post_details((prev) => ({
                                            ...prev,
                                            show_tooltip: false,
                                          }))
                                        }
                                      >
                                        <div className="chart_tooltip_channels_message scroll-custom-thin-bar">
                                          <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                          >
                                            <h6
                                              className="chart_tooltip_channels_title hover_underline_text"
                                              onClick={(e) =>
                                                handleOpenNewTab(
                                                  `/users?page=${
                                                    user_post_details[
                                                      "messages"
                                                    ][
                                                      user_post_details[
                                                        "hovered_userid"
                                                      ]
                                                    ]["user_id"]
                                                  }`,
                                                  e,
                                                )
                                              }
                                            >
                                              {
                                                user_post_details[
                                                  "hovered_userid"
                                                ]
                                              }
                                              <span
                                                style={{ color: "#ff9d00" }}
                                              >
                                                (
                                                {
                                                  user_post_details["chart"][
                                                    user_post_details[
                                                      "hovered_userid"
                                                    ]
                                                  ]
                                                }
                                                )
                                              </span>
                                            </h6>
                                            <CloseIcon
                                              fontSize="small"
                                              sx={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                set_user_post_details(
                                                  (prev) => ({
                                                    ...prev,
                                                    show_tooltip: false,
                                                  }),
                                                )
                                              }
                                            />
                                          </Stack>
                                          <Stack>
                                            {user_post_details["messages"][
                                              user_post_details[
                                                "hovered_userid"
                                              ]
                                            ]["user_posts"].map((item, id) => {
                                              return (
                                                <div
                                                  className="chart_tooltip_posts_div"
                                                  key={id}
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <ShowMoreText
                                                    /* Default options */
                                                    lines={2}
                                                    more="Show more"
                                                    less="Show less"
                                                    className="content-css"
                                                    anchorClass="my-anchor-css-class"
                                                    onClick={executeOnClick}
                                                    expanded={false}
                                                  >
                                                    {item.message}
                                                  </ShowMoreText>
                                                  <p>{date_fm(item.date)}</p>
                                                </div>
                                              );
                                            })}
                                          </Stack>
                                        </div>
                                      </ClickAwayListener>
                                    ) : null}
                                  </div>
                                )
                              ) : (
                                <div
                                  className="card-body scroll-custom"
                                  style={{ height: "325px" }}
                                >
                                  {" "}
                                  <div className="blocks list-tab">
                                    <div className="block orange" />
                                    <div className="block blue" />
                                  </div>
                                  <h6 style={{ marginTop: "-25px" }}>
                                    Data may take time to load
                                  </h6>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              ) : null}
              {file_extension !== null ? (
                <div className="row mt-4">
                  <div className="col-lg-4 new-format">
                    <TransparentContainer
                      heading={" File Post Graph"}
                      container={
                        Object.getOwnPropertyNames(file_extension).length ===
                          0 ||
                        (Array.isArray(file_extension) &&
                          file_extension.length === 0) ? (
                          <div
                            className="card-body card_align_center"
                            style={{ height: "350px" }}
                          >
                            <h2>No Data to Display</h2>
                          </div>
                        ) : (
                          <div
                            className="card-body"
                            style={{ height: "350px" }}
                          >
                            <div>
                              <Chart
                                options={{
                                  chart: {
                                    type: "donut",
                                  },
                                  stroke: {
                                    show: false,
                                  },
                                  legend: {
                                    position: "right",
                                    labels: {
                                      colors: "#fff",
                                    },
                                  },
                                  labels: file_title,
                                  colors: [
                                    "#ec546c",
                                    "#d664c1",
                                    "#28c76f",
                                    "#26b5d3",
                                    "#8c7ae8",
                                  ],
                                  responsive: [
                                    {
                                      breakpoint: 480,
                                      options: {
                                        chart: {
                                          width: 200,
                                        },
                                        legend: {
                                          position: "bottom",
                                        },
                                      },
                                    },
                                  ],
                                }}
                                series={Object.values(file_extension || {})}
                                type="donut"
                                height={300}
                              />
                            </div>
                          </div>
                        )
                      }
                    />
                  </div>
                  <div className="col-lg-4 new-format">
                    <TransparentContainer
                      heading={"No of Files"}
                      container={
                        Object.getOwnPropertyNames(file_extension).length ===
                          0 ||
                        (Array.isArray(file_extension) &&
                          file_extension.length === 0) ? (
                          <div
                            className="card-body card_align_center"
                            style={{ height: "350px" }}
                          >
                            <h2>No Data to Display</h2>
                          </div>
                        ) : (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "350px" }}
                          >
                            {file_title.length > 0
                              ? file_title.map((val, i) => (
                                  <div className="file-tab" key={i}>
                                    <div className="media">
                                      <div className="media-aside mr-1 align-self-start">
                                        <i className={`file-icon lbg-${i}`}>
                                          {icon_returner(val)}
                                        </i>
                                      </div>
                                      <div className="media-body my-auto ">
                                        <div className="file-name ">
                                          <span style={{ marginLeft: "10px" }}>
                                            {val}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <span className="font-weight-bold text-body-heading mr-1">
                                        No of Files:{" "}
                                        {Object.values(file_extension || {})[i]}
                                      </span>
                                      <div
                                        style={{
                                          height: "42px",
                                          width: "42px",
                                        }}
                                      >
                                        <div className="svg-item">
                                          <svg
                                            width="100%"
                                            height="100%"
                                            viewBox="0 0 40 40"
                                            className="donut"
                                          >
                                            <circle
                                              className="donut-hole"
                                              cx="20"
                                              cy="20"
                                              r="15.91549430918954"
                                              fill="#fff"
                                            />
                                            <circle
                                              className="donut-ring"
                                              cx="20"
                                              cy="20"
                                              r="15.91549430918954"
                                              fill="transparent"
                                              strokeWidth="5.5"
                                            />
                                            <circle
                                              className={`donut-segment donut-segment-${i}`}
                                              cx="20"
                                              cy="20"
                                              r="15.91549430918954"
                                              fill="transparent"
                                              strokeWidth="5.5"
                                              strokeDasharray={`${
                                                (Object.values(
                                                  file_extension || {},
                                                )[i] /
                                                  total_file_extension) *
                                                100
                                              } ${
                                                100 -
                                                (Object.values(
                                                  file_extension || {},
                                                )[i] /
                                                  total_file_extension) *
                                                  100
                                              }`}
                                              strokeDashoffset="25"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        )
                      }
                    />
                  </div>
                  <div className="col-lg-4 new-format">
                    <TransparentContainer
                      heading={" Recently Uploaded Files"}
                      container={
                        file_data !== null ? (
                          file_data.length === 0 ? (
                            <div
                              className="card-body card_align_center"
                              style={{ height: "350px" }}
                            >
                              <h2>No Data to Display</h2>
                            </div>
                          ) : (
                            <div
                              className="card-body scroll-custom"
                              style={{ height: "350px" }}
                            >
                              {file_data !== null
                                ? file_data.map((val, i) => {
                                    let filename = "";
                                    try {
                                      if (
                                        val.filename.includes(
                                          "SIZE_EXCEEDED_NOT_DOWNLOADED_",
                                        ) === true
                                      ) {
                                        filename = val.filename.replace(
                                          "SIZE_EXCEEDED_NOT_DOWNLOADED_",
                                          "",
                                        );
                                      } else {
                                        filename = val.filename;
                                      }
                                    } catch {
                                      filename = val.filename;
                                    }
                                    return (
                                      <div className="file-tab" key={i}>
                                        <div
                                          className="media"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            file_link_adder(
                                              val.link,
                                              val.msgid,
                                            );
                                          }}
                                        >
                                          <div className="media-aside mr-1 align-self-start">
                                            <div className="filessett" />
                                          </div>
                                          <div className="media-body my-auto ">
                                            <div
                                              style={{
                                                maxWidth: "100%",
                                                color: "#359daf",
                                                marginLeft: "15px",
                                              }}
                                              className="telefilename"
                                            >
                                              {filename}
                                            </div>
                                            <div
                                              style={{ marginLeft: "15px" }}
                                              className="telefilesize"
                                            >
                                              {byte_checker(val.filesize)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          )
                        ) : (
                          <div
                            className="card-body"
                            style={{ height: "350px" }}
                          >
                            {" "}
                            <div className="blocks list-tab">
                              <div className="block orange" />
                              <div className="block blue" />
                            </div>
                            <h6 style={{ marginTop: "-25px" }}>
                              Data may take time to load
                            </h6>
                          </div>
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}
              <div className="row mt-4">
                <div className="col-md-offset-1 col-md-8">
                  <TransparentContainer
                    heading={"Forwarded Messages"}
                    container={
                      <div className="card-body" style={{ maxHeight: "550px" }}>
                        <div className="table-wrap scroll-custom">
                          {forwarded_data !== null &&
                          typeof forwarded_data !== "undefined" ? (
                            forwarded_data.length < 1 ? (
                              <div
                                className="card-body card_align_center"
                                style={{ height: "350px" }}
                              >
                                <h2>No Data to Display</h2>
                              </div>
                            ) : (
                              <table className="table">
                                <thead className="thead-primary">
                                  <tr>
                                    <th>#</th>
                                    <th>Forwaded Message</th>
                                    <th>Forwaded Channel</th>
                                    <th>Forwaded Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {forwarded_data.map((val, i) => (
                                    <tr key={i}>
                                      <td>{i}</td>
                                      <td
                                        style={{
                                          width: "500px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {val.message ? (
                                          val.message.length > 0 ? (
                                            <ShowMoreText
                                              lines={2}
                                              more="Show more"
                                              less="Show less"
                                              onClick={executeOnClick}
                                              expanded={false}
                                              width={500}
                                              truncatedEndingComponent="... "
                                              className="content-css"
                                            >
                                              {val.message}
                                            </ShowMoreText>
                                          ) : (
                                            "Message not available"
                                          )
                                        ) : (
                                          "Message not available"
                                        )}
                                      </td>
                                      <td>{forwaded_formatter(val)}</td>
                                      <td className="text-nowrap">
                                        {date_fm(val.date)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )
                          ) : (
                            <div
                              className="card-body scroll-custom"
                              style={{ height: "325px" }}
                            >
                              {" "}
                              <div className="blocks list-tab">
                                <div className="block orange" />
                                <div className="block blue" />
                              </div>
                              <h6 style={{ marginTop: "-25px" }}>
                                Data may take time to load
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="col-md-offset-1 col-md-4">
                  <TransparentContainer
                    container={
                      <div>
                        <div className="card-header" style={{ color: "white" }}>
                          <div
                            className="stats_title_color"
                            style={{
                              background: "none",
                              textTransform: "capitalize",
                            }}
                          >
                            <span style={{ fontSize: "20px" }}>
                              Forwarded Categories{" "}
                              {forwarded_channels !== null ? (
                                <HtmlTooltip title="This pie chart represents percentage of forwarded posts from each category.">
                                  <InfoIcon
                                    sx={{ color: "#b3a800", marginLeft: "4px" }}
                                  />
                                </HtmlTooltip>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={{ maxHeight: "550px" }}
                        >
                          <div className="table-wrap scroll-custom">
                            {forwarded_data !== null &&
                            typeof forwarded_data !== "undefined" &&
                            forwarded_channels !== null ? (
                              forwarded_data.length < 1 ? (
                                <div
                                  className="card-body card_align_center"
                                  style={{ height: "350px" }}
                                >
                                  <h2>No Data to Display</h2>
                                </div>
                              ) : (
                                <div className="channel_stats_forwarded_channels">
                                  <Chart
                                    options={{
                                      chart: {
                                        type: "pie",
                                        height: 350,
                                      },
                                      stroke: {
                                        show: false,
                                      },
                                      legend: {
                                        position: "bottom",
                                        labels: {
                                          colors: "#fff",
                                        },
                                      },
                                      tooltip: {
                                        custom: function ({ seriesIndex, w }) {
                                          set_forwarded_categories_name(
                                            w["config"]["labels"][seriesIndex],
                                          );
                                          set_show_forwarded_category_tooltip(
                                            true,
                                          );
                                          return null;
                                        },
                                      },
                                      labels: Object.keys(
                                        forwarded_channels["pie_chart"],
                                      ),
                                      colors: chart_color_array,
                                      responsive: [
                                        {
                                          breakpoint: 480,
                                          options: {
                                            chart: {
                                              width: 200,
                                            },
                                            legend: {
                                              position: "bottom",
                                            },
                                          },
                                        },
                                      ],
                                    }}
                                    series={Object.values(
                                      forwarded_channels["pie_chart"] || {},
                                    )}
                                    type="pie"
                                    height={350}
                                  />
                                  {show_forwarded_category_tooltip === true ? (
                                    <ClickAwayListener
                                      onClickAway={() =>
                                        set_show_forwarded_category_tooltip(
                                          false,
                                        )
                                      }
                                    >
                                      <div className="chart_tooltip_channels scroll-custom-thin-bar">
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                        >
                                          <h6 className="chart_tooltip_channels_title">
                                            {forwarded_categories_name.replaceAll(
                                              "_",
                                              " ",
                                            )}
                                            <span style={{ color: "#ff9d00" }}>
                                              (
                                              {
                                                forwarded_channels[
                                                  "categories_channel"
                                                ][forwarded_categories_name][
                                                  "value"
                                                ]
                                              }
                                              )
                                            </span>
                                          </h6>
                                          <CloseIcon
                                            fontSize="small"
                                            sx={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              set_show_forwarded_category_tooltip(
                                                false,
                                              )
                                            }
                                          />
                                        </Stack>
                                        <Stack>
                                          {Object.entries(
                                            forwarded_channels[
                                              "categories_channel"
                                            ][forwarded_categories_name][
                                              "channels"
                                            ],
                                          ).map(([key, value], id) => {
                                            return (
                                              <Grid container key={id}>
                                                <Grid item xs={8.5}>
                                                  <p
                                                    className="chart_tooltip_channels_name_pie_chart hover_underline_text"
                                                    onClick={(e) =>
                                                      handleOpenNewTab(
                                                        `/page?page=${key}&type=channel name`,
                                                        e,
                                                      )
                                                    }
                                                  >
                                                    {key}
                                                  </p>
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                  <p className="chart_tooltip_channels_post_no">
                                                    {value}
                                                  </p>
                                                </Grid>
                                              </Grid>
                                            );
                                          })}
                                        </Stack>
                                      </div>
                                    </ClickAwayListener>
                                  ) : null}
                                </div>
                              )
                            ) : (
                              <div
                                className="card-body scroll-custom"
                                style={{ height: "325px" }}
                              >
                                {" "}
                                <div className="blocks list-tab">
                                  <div className="block orange" />
                                  <div className="block blue" />
                                </div>
                                <h6 style={{ marginTop: "-25px" }}>
                                  Data may take time to load
                                </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <TransparentContainer
                        container={
                          <div className="row">
                            <div className="col-lg-8 col-md-8">
                              <h6
                                className="stats_title_color"
                                style={{
                                  lineHeight: "1.2",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                Total Mention{" "}
                              </h6>
                              <h2
                                style={{ fontSize: "16px" }}
                                className="font-weight-bolder mb-1"
                              >
                                {" "}
                                {total_mention_post !== null
                                  ? total_mention_post
                                  : "..."}
                              </h2>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <div className="icon-holder one">
                                <IconContext.Provider
                                  value={{
                                    size: 24,
                                    marginTop: "10px",
                                    marginLeft: "12px",
                                    color: "white",
                                  }}
                                >
                                  <FaAt />
                                </IconContext.Provider>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                    <div className="col-lg-6">
                      <TransparentContainer
                        container={
                          <div className="row">
                            <div className="col-lg-8 col-md-8">
                              <h6
                                className="stats_title_color"
                                style={{
                                  lineHeight: "1.2",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                Total Hash Post{" "}
                              </h6>
                              <h2
                                className="font-weight-bolder mb-1"
                                style={{ fontSize: "16px" }}
                              >
                                {" "}
                                {total_hash_post !== null
                                  ? total_hash_post
                                  : "..."}
                              </h2>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <div
                                className="icon-holder two"
                                style={{
                                  background:
                                    "linear-gradient(to bottom right, #4be8d4 0%, #129bd2 100%) !important",
                                }}
                              >
                                <IconContext.Provider
                                  value={{
                                    size: 24,
                                    marginTop: "10px",
                                    marginLeft: "12px",
                                    color: "white",
                                  }}
                                >
                                  <FaHashtag />
                                </IconContext.Provider>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <TransparentContainer
                        container={
                          <div className="row" style={{ height: "237px" }}>
                            <div className="col-6 text-left">
                              <h5 className="stats_title_color">
                                Mention Post Percent
                              </h5>
                              {total_mention_post_percent !== null ? (
                                total_mention_post_percent.length === 0 ? (
                                  <div
                                    className="card-body card_align_center"
                                    style={{ height: "100%" }}
                                  >
                                    <h2>No Data to Display</h2>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      height: "140px",
                                      width: "120px",
                                      marginTop: "35px",
                                      marginLeft: "30px",
                                    }}
                                  >
                                    <div className="svg-item">
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 40 40"
                                        className="donut"
                                      >
                                        <circle
                                          className="donut-hole"
                                          cx="20"
                                          cy="20"
                                          r="15.91549430918954"
                                          fill="transparent"
                                        />
                                        <circle
                                          className="donut-ring"
                                          cx="20"
                                          cy="20"
                                          r="15.91549430918954"
                                          fill="transparent"
                                          strokeWidth="5.5"
                                        />
                                        <circle
                                          className="donut-segment donut-segment-8"
                                          cx="20"
                                          cy="20"
                                          r="15.91549430918954"
                                          fill="transparent"
                                          strokeWidth="5.5"
                                          strokeDasharray={`${total_mention_post_percent} ${
                                            100 - total_mention_post_percent
                                          }`}
                                          strokeDashoffset="25"
                                        />
                                        <g className="donut-text donut-text-8">
                                          <text
                                            y="50%"
                                            transform="translate(0, 2)"
                                          >
                                            <tspan
                                              x="50%"
                                              textAnchor="middle"
                                              className="donut-percent"
                                            >
                                              {total_mention_post_percent.toFixed(
                                                1,
                                              )}{" "}
                                              %
                                            </tspan>
                                          </text>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div className="blocks list-tab">
                                  <div className="block orange" />
                                  <div className="block blue" />
                                </div>
                              )}
                            </div>
                            <div className="col-6 text-left">
                              <h5 className="stats_title_color">
                                {" "}
                                Mention Hash Percent
                              </h5>
                              {total_hash_post_percent !== null ? (
                                <div
                                  style={{
                                    height: "140px",
                                    width: "120px",
                                    marginTop: "35px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <div className="svg-item">
                                    <svg
                                      width="100%"
                                      height="100%"
                                      viewBox="0 0 40 40"
                                      className="donut"
                                    >
                                      <circle
                                        className="donut-hole"
                                        cx="20"
                                        cy="20"
                                        r="15.91549430918954"
                                        fill="transparent"
                                      />
                                      <circle
                                        className="donut-ring"
                                        cx="20"
                                        cy="20"
                                        r="15.91549430918954"
                                        fill="transparent"
                                        strokeWidth="5.5"
                                      />
                                      <circle
                                        className="donut-segment donut-segment-9"
                                        cx="20"
                                        cy="20"
                                        r="15.91549430918954"
                                        fill="transparent"
                                        strokeWidth="5.5"
                                        strokeDasharray={`${total_hash_post_percent} ${
                                          100 - total_hash_post_percent
                                        }`}
                                        strokeDashoffset="25"
                                      />

                                      <g className="donut-text donut-text-9">
                                        <text
                                          y="50%"
                                          transform="translate(0, 2)"
                                        >
                                          <tspan
                                            x="50%"
                                            textAnchor="middle"
                                            className="donut-percent"
                                          >
                                            {total_hash_post_percent !== null
                                              ? total_hash_post_percent.toFixed(
                                                  1,
                                                )
                                              : "..."}{" "}
                                            %
                                          </tspan>
                                        </text>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              ) : (
                                <div className="blocks">
                                  <div className="block orange" />
                                  <div className="block blue" />
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 new-format">
                  <TransparentContainer
                    heading={" No of Mentions"}
                    container={
                      mention_data !== null ? (
                        sort_obj_values(mention_data).length === 0 ? (
                          <div
                            className="card-body card_align_center"
                            style={{ height: "325px" }}
                          >
                            <h2>No Data to Display</h2>
                          </div>
                        ) : (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "325px" }}
                          >
                            {sort_obj_values(mention_data).map(
                              ([first, second], i) => (
                                <div className="file-tab" key={i}>
                                  <div className="media">
                                    <div className="media-aside mr-1 align-self-start">
                                      <i className="file-icon lbg-mention">
                                        <IconContext.Provider
                                          value={{
                                            size: 18,
                                            marginTop: "10px",
                                            marginLeft: "12px",
                                            color: "white",
                                          }}
                                        >
                                          <FaAt />
                                        </IconContext.Provider>
                                      </i>
                                    </div>
                                    <div className="media-body my-auto ">
                                      <div className="file-name">
                                        <span style={{ marginLeft: "10px" }}>
                                          {first}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <span className="font-weight-bold text-body-heading mr-1">
                                      Total: {second}
                                    </span>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )
                      ) : (
                        <div
                          className="card-body scroll-custom"
                          style={{ height: "325px" }}
                        >
                          {" "}
                          <div className="blocks list-tab">
                            <div className="block orange" />
                            <div className="block blue" />
                          </div>
                          <h6 style={{ marginTop: "-25px" }}>
                            Data may take time to load
                          </h6>
                        </div>
                      )
                    }
                  />
                </div>
                <div className="col-lg-4 new-format">
                  <TransparentContainer
                    heading={"  No of Hashtag"}
                    container={
                      hash_data !== null ? (
                        sort_obj_values(hash_data).length === 0 ? (
                          <div
                            className="card-body card_align_center"
                            style={{ height: "325px" }}
                          >
                            <h2>No Data to Display</h2>
                          </div>
                        ) : (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "325px" }}
                          >
                            {sort_obj_values(hash_data).map(
                              ([first, second], i) => (
                                <div className="file-tab" key={i}>
                                  <div className="media">
                                    <div className="media-aside mr-1 align-self-start">
                                      <i className="file-icon lbg-hashtag">
                                        <IconContext.Provider
                                          value={{
                                            size: 18,
                                            marginTop: "10px",
                                            marginLeft: "12px",
                                            color: "white",
                                          }}
                                        >
                                          <FaHashtag />
                                        </IconContext.Provider>
                                      </i>
                                    </div>
                                    <div className="media-body my-auto ">
                                      <div className="file-name ">
                                        <span style={{ marginLeft: "10px" }}>
                                          {first}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <span className="font-weight-bold text-body-heading mr-1">
                                      Total: {second}
                                    </span>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )
                      ) : (
                        <div
                          className="card-body scroll-custom"
                          style={{ height: "325px" }}
                        >
                          {" "}
                          <div className="blocks list-tab">
                            <div className="block orange" />
                            <div className="block blue" />
                          </div>
                          <h6 style={{ marginTop: "-25px" }}>
                            Data may take time to load
                          </h6>
                        </div>
                      )
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default Pagestats;
