import React, { useContext, memo } from "react";
import { Stack } from "@mui/material";
import BreachData from "../breachdata/BreachData";
import { ThreeCircles } from "react-loader-spinner";
import { dashbaord_search_context } from "./UniversalTableDashboard";
import { handle_determine_dashboard_breach_search_subtype } from "./companyglobalfunc";
function DashboardBreachTable() {
  const { breach_data, getSearchedData } = useContext(dashbaord_search_context);
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "97%" }}
      >
        <h4
          className="dashboard_search_table_heading"
          style={{ marginBottom: "1rem" }}
        >
          Breach search
        </h4>
      </Stack>
      <div style={{ position: "relative" }}>
        <div className="dashbaord_table_telegram_search scroll-custom">
          {breach_data.loading === true ? (
            <div className="spinner">
              <ThreeCircles
                color="#28cab3"
                height={60}
                width={60}
                ariaLabel="three-circles-rotating"
              />
            </div>
          ) : breach_data.error ? (
            <div className="main__table no_data">{breach_data.error}</div>
          ) : breach_data.data.length === 0 ? (
            <div className="main__table no_data">No data to display</div>
          ) : (
            <BreachData
              breach_data={breach_data.data}
              template_breach_parameter={handle_determine_dashboard_breach_search_subtype(
                getSearchedData,
              )}
              get_searched_data_breach={getSearchedData}
              total_db_data={breach_data.total_data || 100}
              breach_rate_limit={breach_data.rate_limit || 100}
              file_id={breach_data.file_id || ""}
              table_mode="dashboard_search_table"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(DashboardBreachTable);
