import axios from "axios";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";

export const handle_get_channels = (category, set_channels_name) => {
  let isSubmitBlocked = false;
  set_channels_name({ data: [], loading: true, error: "" });
  const token = handle_session_get("Token");
  if (!isSubmitBlocked) {
    isSubmitBlocked = true;
    axios
      .get(`https://dev.recordedtelegram.com/bucket-files/${category}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_channels_name({
          loading: false,
          error: "",
          data: res.data.folders,
        });
        isSubmitBlocked = false;
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_channels_name({
          loading: false,
          error: err.response.data.errormsg,
          data: [],
        });
        isSubmitBlocked = false;
      });
  }
};
export const handle_get_channels_files = (
  category,
  channel,
  set_files_name,
  selected_extension,
) => {
  let isSubmitBlocked = false;
  set_files_name((prev) => ({ ...prev, loading: true, error: "" }));
  const token = handle_session_get("Token");
  if (!isSubmitBlocked) {
    isSubmitBlocked = true;
    const handle_url =
      selected_extension === "all"
        ? `https://dev.recordedtelegram.com/bucket-files/${category}/${channel}`
        : `https://dev.recordedtelegram.com/bucket-files/${category}/${channel}/${selected_extension}`;
    axios
      .get(handle_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_files_name({
          loading: false,
          error: "",
          data: res.data.files,
          file_ext: res.data.file_extension,
          media_limit: res.data.company_media_limit,
        });
        isSubmitBlocked = false;
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_files_name((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.errormsg,
          data: [],
        }));
        isSubmitBlocked = false;
      });
  }
};

export const formatFileSize = (fileSizeBytes) => {
  try {
    if (fileSizeBytes < 1024) {
      // Less than 1 KB
      return `${fileSizeBytes} Bytes`;
    } else if (fileSizeBytes < 1024 ** 2) {
      // Less than 1 MB
      const sizeKB = fileSizeBytes / 1024;
      return `${sizeKB.toFixed(2)} KB`;
    } else if (fileSizeBytes < 1024 ** 3) {
      // Less than 1 GB
      const sizeMB = fileSizeBytes / 1024 ** 2;
      return `${sizeMB.toFixed(2)} MB`;
    } else {
      // Greater than or equal to 1 GB
      const sizeGB = fileSizeBytes / 1024 ** 3;
      return `${sizeGB.toFixed(2)} GB`;
    }
  } catch {
    return fileSizeBytes;
  }
};

export const slicetwodecimal = (num, decimals) => {
  try {
    const factor = Math.pow(10, decimals);
    return Math.floor(num * factor) / factor;
  } catch {
    return num;
  }
};
