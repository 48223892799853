import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_custom_debounce } from "../../../utils/Globals/global_functions";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
const CustomDropdown = ({
  all_clients,
  set_all_clients,
  selected_user,
  set_selected_user,
  set_selected_user_id,
}) => {
  const [show_dropdown, set_show_dropdown] = useState(false);
  const token = handle_session_get("Token");

  const handleChange = (e) => {
    const value = e.target.value;
    set_selected_user(value);
    debouncedFetchData(value);
  };
  const handle_get_all_clients = (user) => {
    if (user.length > 0) {
      axios
        .get(`https://dev.recordedtelegram.com/api/clients/?username=${user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_all_clients(res.data.data);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
        });
    }
  };
  const debouncedFetchData = useCallback(
    handle_custom_debounce(handle_get_all_clients, 300),
    [],
  );
  return (
    <div className="custom_dropdown">
      <input
        className="global_input_box"
        type="text"
        required
        value={selected_user}
        onChange={handleChange}
        onClick={() => set_show_dropdown(true)}
        placeholder="Enter username"
        style={{ borderRadius: "20px", marginBlockEnd: "0" }}
      />
      {show_dropdown === true && selected_user ? (
        <ClickAwayListener onClickAway={() => set_show_dropdown(false)}>
          <ul className="custom_dropdown_options scroll-custom">
            {all_clients.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  set_selected_user(option.username);
                  set_selected_user_id(option.userid);
                  set_show_dropdown(false);
                }}
                className="dropdown-option"
              >
                {option.username}
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};
CustomDropdown.propTypes = {
  all_clients: PropTypes.array,
  set_all_clients: PropTypes.func,
  selected_user: PropTypes.string,
  set_selected_user: PropTypes.func,
  set_selected_user_id: PropTypes.func,
};

export default CustomDropdown;
