import React, { useState, useMemo } from "react";
import {
  handle_get_channels,
  handle_get_channels_files,
} from "./downloadfilesfunctions";
import { options_post_category } from "../../../Assets/globalfunctions";
import { rename_all_categories_name } from "../../../utils/Globals/global_functions";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import {
  handle_error_message,
  handle_warning_message,
} from "../../../utils/ResponseMessage/response_message";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MovieIcon from "@mui/icons-material/Movie";
import ImageIcon from "@mui/icons-material/Image";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import GridViewIcon from "@mui/icons-material/GridView";
import MenuIcon from "@mui/icons-material/Menu";
import { RoundAnimationLoader, DotsLoading } from "../loader/Loader";
import { ToastContainer } from "react-toastify";
import fileDownload from "js-file-download";
import { formatFileSize } from "./downloadfilesfunctions";
import axios from "axios";
import { Grid } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Stack } from "@mui/material";
import CustomizedMenus from "../sections/Dropdown/SelectMenu";
import { slicetwodecimal } from "./downloadfilesfunctions";
import "../../../css/downloadfiles/downloadfiles.css";

function DownloadFiles() {
  const [selected_category, set_selected_category] = useState("");
  const [selected_channel, set_seletcted_channel] = useState("");
  const [checked_file, set_checked_file] = useState([]);
  const [view_type, set_view_type] = useState("grid");
  const [channels_name, set_channels_name] = useState({
    data: [],
    loading: false,
    error: "",
  });
  const [files_name, set_files_name] = useState({
    data: [],
    loading: false,
    error: "",
    file_ext: [],
    media_limit: null,
  });
  const [file_downlod_loading, set_file_download_loading] = useState(false);
  const [all_file_download_loading, set_all_file_download_loading] =
    useState(false);
  const [clicked_file_table, set_clicked_file_table] = useState("");
  const [option_dropdown, set_option_dropdown] = useState("all");
  // const downloadFile = async (url, filename, new_limit) => {
  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //     });
  //     if (!response.ok) {
  //       throw new Error(`Failed to download file.`);
  //     }
  //     const blob = await response.blob();
  //     set_file_download_loading(false);
  //     set_files_name((prev) => ({ ...prev, media_limit: new_limit }));
  //     fileDownload(blob, filename);
  //   } catch (error) {
  //     handle_error_message(error ? error : "Failed to download file.");
  //   }
  // };
  const handle_calculate_total_size = useMemo(() => {
    if (files_name?.data.length > 0) {
      const all_files_size = files_name.data.map((item) => item.size);
      const file_size_sum = all_files_size.reduce((sum, num) => sum + num, 0);
      const file_size_format = formatFileSize(file_size_sum);
      return file_size_format;
    }
    return 0;
  }, [files_name?.data]);

  const handle_calculate_file_size_validation = (download_type) => {
    if (files_name?.data.length > 0) {
      const filter_undownloaded = files_name.data.filter(
        (item) => !item.is_downloaded,
      );
      const sanitize_file_download_type =
        download_type === "all" ? files_name.data : filter_undownloaded;
      const all_files_size = sanitize_file_download_type.map(
        (item) => item.size,
      );
      const file_size_sum = all_files_size.reduce((sum, num) => sum + num, 0);
      if (files_name?.media_limit && files_name.media_limit !== null) {
        const file_size_in_gb = file_size_sum / (1024 * 1024 * 1024);
        if (file_size_in_gb > files_name.media_limit) {
          return "exceed";
        }
      }
    }
    return "not_exceed";
  };

  const handle_download_files = (category, channel, files) => {
    let isSubmitBlocked = false;
    set_file_download_loading(true);
    const token = handle_session_get("Token");
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      const data = {
        bucket: category,
        subfolder: channel,
        selected_files: files
      }
      axios
        .post(
          `https://dev.recordedtelegram.com/selected-bucket-download`, data,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          set_file_download_loading(false);
          const filename =
            `${selected_channel}_selected_files.zip` ?? "selected_files.zip";
          fileDownload(res.data, filename);
          set_checked_file([])
          handle_get_media_limit()
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_file_download_loading(false);
          let errorMessage = "";
          const reader = new FileReader();
          reader.onload = () => {
            errorMessage = reader.result;
            handle_error_message(JSON.parse(errorMessage).errormsg);
          };
          reader.readAsText(err.response.data);
          isSubmitBlocked = false;
        });
    }
  };

  const handle_all_bucket_download = (download_type) => {
    let isSubmitBlocked = false;
    if (handle_calculate_file_size_validation(download_type) === "exceed") {
      handle_warning_message(
        "Sorry, Your file size exceed the allocated media limit.",
      );
      return;
    }
    set_all_file_download_loading(true);
    const token = handle_session_get("Token");
    const url =
      option_dropdown !== "all"
        ? `https://dev.recordedtelegram.com/all-bucket-download/?bucket=${selected_category}&subfolder=${selected_channel}&extension=${option_dropdown}&exclude_downloaded=${download_type === "undownloaded"
        }`
        : `https://dev.recordedtelegram.com/all-bucket-download/?bucket=${selected_category}&subfolder=${selected_channel}&exclude_downloaded=${download_type === "undownloaded"
        }`;
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .get(url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          set_all_file_download_loading(false);
          const filename =
            `${selected_channel}_all_files.zip` ?? "all_download.zip";
          fileDownload(response.data, filename);
          handle_get_media_limit()
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_all_file_download_loading(false);
          let errorMessage = "";
          const reader = new FileReader();
          reader.onload = () => {
            errorMessage = reader.result;
            handle_error_message(JSON.parse(errorMessage).errormsg);
          };
          reader.readAsText(err.response.data);
          isSubmitBlocked = false;
        });
    }
  };

  const handle_get_media_limit = () => {
    let isSubmitBlocked = false;
    const token = handle_session_get("Token");
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .get("https://dev.recordedtelegram.com/get_user_media_limit", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          set_files_name(prev=>({...prev,media_limit:response.data.company_media_limit}))
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          isSubmitBlocked = false;
        });
    }
  };
  const handle_multiple_file_selection = (selected_file_name) => {
    const clone_file_array = [...checked_file]
    const file_index = clone_file_array.indexOf(selected_file_name);
    file_index < 0 ? clone_file_array.push(selected_file_name) : clone_file_array.splice(file_index, 1)
    set_checked_file(clone_file_array)
  }
  return (
    <div className="download_files_container">
      <div className="download_files_categories_container">
        <h4 className="download_files_heading">Categories</h4>
        <div className="download_files_categories scroll-custom">
          {options_post_category.map((item, index) => {
            let clicked_category = "";
            switch (item) {
              case "hacking":
                clicked_category = "hacking";
                break;
              case "extremepolitical":
                clicked_category = "political";
                break;
              case "pharma_drugs":
                clicked_category = "pharma";
                break;
              default:
                clicked_category = item;
                break;
            }
            return (
              <p
                key={index}
                className={
                  selected_category !== clicked_category
                    ? "download_files_list download_files_category_name"
                    : "download_files_list download_files_category_name download_files_category_name_active"
                }
                onClick={() => {
                  set_selected_category(clicked_category);
                  set_checked_file([]);
                  set_seletcted_channel("");
                  set_files_name({
                    data: [],
                    loading: false,
                    error: "",
                    file_ext: [],
                    media_limit: null,
                  });
                  handle_get_channels(clicked_category, set_channels_name);
                }}
              >
                {rename_all_categories_name(item)}
              </p>
            );
          })}
        </div>
      </div>
      <div className="download_files_channels_container">
        <h4 className="download_files_heading">Channels/Groups</h4>
        <div className="download_files_channels scroll-custom">
          {channels_name.loading === true ? (
            <RoundAnimationLoader />
          ) : channels_name.error ? (
            <p className="download_files_error_msg">{channels_name.error}</p>
          ) : !selected_category ? (
            <p className="download_files_error_msg">
              Please select a category.
            </p>
          ) : selected_category && channels_name.data.length === 0 ? (
            <p className="download_files_error_msg">No data to display</p>
          ) : (
            channels_name.data.map((item, index) => {
              return (
                <p
                  key={index}
                  className={
                    selected_channel !== item
                      ? "download_files_list download_files_channel_name"
                      : "download_files_list download_files_channel_name download_files_category_name_active"
                  }
                  onClick={() => {
                    set_seletcted_channel(item);
                    handle_get_channels_files(
                      selected_category,
                      item,
                      set_files_name,
                      "all",
                    );
                    set_option_dropdown("all");
                  }}
                >
                  {item}
                </p>
              );
            })
          )}
        </div>
      </div>

      <div className="download_files_folders">
        <Grid container>
          <Grid container direction="row" item xs={8}>
            {
              files_name.media_limit !== null ?

                <p className="download_files_limit_info">
                  Media limit :{" "}
                  <span style={{ color: "orange" }}>{files_name?.media_limit ? `${slicetwodecimal(
                    files_name.media_limit,
                    5,
                  )} GB` : 0}</span>
                </p>
                : null
            }
            {handle_calculate_total_size ? (
              <>
                <div
                  className="download_file_display_bts_divider"
                  style={{ margin: "0 10px" }}
                />
                <p className="download_files_limit_info  download_files_limit_info_total">
                  Total size :{" "}
                  <span style={{ color: "orange" }}>
                    {handle_calculate_total_size}
                  </span>
                </p>
              </>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            {" "}
            {selected_channel ? (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                columnGap={2}
              >
                {checked_file.length > 0 ? (
                  file_downlod_loading === true ? (
                    <div className="download_file_btn">
                      <DotsLoading />
                    </div>
                  ) : (
                    <button
                      className="download_file_btn"
                      onClick={() => {
                        if (checked_file.length > 0) {
                          handle_download_files(
                            selected_category,
                            selected_channel,
                            checked_file
                          );
                        } else {
                          handle_warning_message("Please choose a file first.");
                        }
                      }}
                    >
                      <FileDownloadIcon sx={{ color: "#e19813" }} />
                    </button>
                  )
                ) : null}
                {files_name.data.length > 0 ? (
                  all_file_download_loading === true ? (
                    <DotsLoading />
                  ) : (
                    <CustomizedMenus
                      handle_all_bucket_download={handle_all_bucket_download}
                    />
                  )
                ) : null}
                {files_name.file_ext.length > 0 && (
                  <FormControl
                    sx={{
                      minWidth: 100,
                      border: "1px solid #3cd2a5",
                      borderRadius: "2.25rem",
                    }}
                    size="small"
                  >
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={option_dropdown}
                      onChange={(e) => {
                        set_option_dropdown(e.target.value);
                        handle_get_channels_files(
                          selected_category,
                          selected_channel,
                          set_files_name,
                          e.target.value,
                        );
                        set_checked_file([]);
                      }}
                      sx={{ width: "100%" }}
                      disableUnderline
                      variant="standard"
                    >
                      {files_name.file_ext.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value="all">All</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {files_name.data.length > 0 && (
                  <div className="download_file_view_type_box">
                    <button
                      className="download_file_view_type_btn"
                      onClick={() => set_view_type("grid")}
                    >
                      <GridViewIcon fontSize="small" />
                    </button>
                    <div className="download_file_display_bts_divider" />
                    <button
                      className="download_file_view_type_btn"
                      onClick={() => {
                        set_view_type("list");
                        set_checked_file([]);
                      }}
                    >
                      <MenuIcon fontSize="small" />
                    </button>
                  </div>
                )}
              </Stack>
            ) : null}
          </Grid>
        </Grid>

        <div className="download_files_folder_names_container scroll-custom">
          {files_name.loading === true ? (
            <RoundAnimationLoader />
          ) : files_name.error ? (
            <p className="download_files_error_msg">{files_name.error}</p>
          ) : selected_channel && files_name.data.length === 0 ? (
            <p
              className="download_files_error_msg"
              style={{ textAlign: "center", margin: "5rem auto" }}
            >
              No data to display
            </p>
          ) :
            files_name.data.length > 0 ?
              view_type === "grid" ? (
                files_name.data.map((item, index) => {
                  let file_type = "";
                  if (item.type.startsWith("video")) {
                    file_type = "video";
                  } else if (item.type.startsWith("image")) {
                    file_type = "image";
                  } else if (item.type.startsWith("audio")) {
                    file_type = "audio";
                  } else {
                    file_type = "other";
                  }
                  const split_file_name = item.name.split("/");
                  const filter_file_name =
                    split_file_name[split_file_name.length - 1] ?? item.name;
                  const file_size = formatFileSize(item.size);
                  return (
                    <div
                      key={index}
                      className={
                        checked_file.includes(item.name)
                          ? "download_files_folder_box download_files_folder_box_active"
                          : "download_files_folder_box"
                      }
                      onClick={() => {
                        handle_multiple_file_selection(item.name)
                      }}
                    >
                      {checked_file.includes(item.name) && (
                        <input
                          type="checkbox"
                          className="download_files_check_box"
                          checked={checked_file.includes(item.name)}
                        />
                      )}
                      {item.is_downloaded === true ? (
                        <span className="downlod_file_is_downloaded_info">
                          Downloaded
                        </span>
                      ) : null}
                      <div className="download_files_folder_shape">
                        {item.thumbnail ? (
                          <img
                            width={220}
                            height={220}
                            src={item.thumbnail}
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <InsertDriveFileIcon
                            sx={{ fontSize: 220, color: "#b0b0b0" }}
                          />
                        )}
                      </div>
                      <p className="download_files_folder_name">
                        {" "}
                        {file_type === "video" ? (
                          <MovieIcon
                            fontSize="small"
                            sx={{ mr: 1, color: "orange" }}
                          />
                        ) : file_type === "image" ? (
                          <ImageIcon
                            fontSize="small"
                            sx={{ mr: 1, color: "orange" }}
                          />
                        ) : file_type === "audio" ? (
                          <AudioFileIcon
                            fontSize="small"
                            sx={{ mr: "3px", color: "orange" }}
                          />
                        ) : null}
                        <span className="download_file_file_size_info">
                          ({file_size})
                        </span>
                        {filter_file_name}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div>
                  <table className="download_file_view_table">
                    <thead>
                      <tr>
                        <th width="4%"></th>
                        <th width="42%">Name</th>
                        <th width="20%">Type</th>
                        <th width="15%">Size</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files_name.data.map((item, index) => {
                        const split_file_name = item.name.split("/");
                        const filter_file_name =
                          split_file_name[split_file_name.length - 1] ?? item.name;
                        const file_size = formatFileSize(item.size);
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                className="download_files_check_box_table"
                                checked={checked_file.includes(item.name)}
                                onChange={() => handle_multiple_file_selection(item.name)}
                              />
                            </td>
                            <td>{filter_file_name}</td>
                            <td>{item.type}</td>
                            <td>{file_size}</td>
                            <td>
                              {clicked_file_table === item.name &&
                                file_downlod_loading === true ? (
                                <DotsLoading />
                              ) : (
                                <>
                                  <button
                                    className="download_file_table_btn"
                                    onClick={() => {
                                      handle_download_files(
                                        selected_category,
                                        selected_channel,
                                        [item.name],
                                      );
                                      set_clicked_file_table(item.name);
                                    }}
                                  >
                                    <FileDownloadIcon sx={{ color: "#e19813" }} />
                                  </button>{" "}
                                  {item.is_downloaded === true ? (
                                    <span className="downlod_file_is_downloaded_info_table">
                                      Downloaded
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )
              :
              null
          }
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DownloadFiles;
