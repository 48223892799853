import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import {
  handle_success_message,
  handle_error_message,
} from "../../../utils/ResponseMessage/response_message";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { handle_open_channel_scrape_modal } from "../../../actions/filterAction";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import { options_post_category } from "../../../Assets/globalfunctions";
import { rename_all_categories_name } from "../../../utils/Globals/global_functions";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function ChannelScrapeRequest() {
  const open_modal = useSelector(
    (state) => state.filter_reducer.channel_scrape_modal,
  );
  const [open_category_dropdown, set_open_category_dropdown] = useState(false);
  const [selected_category, set_selected_category] = useState("");
  const dispatch = useDispatch();
  const token = handle_session_get("Token");
  let isSubmitBlocked = false;
  const handle_add_channel_request = (channel_link, category, media) => {
    const data = {
      channel_link: channel_link,
      category: category,
      media_type: media==="download_files"?"media":"message",
    };
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post("https://dev.recordedtelegram.com/scrapper_request", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          handle_success_message(res.data.message);
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          handle_error_message(err.response.data.errormsg);
          isSubmitBlocked = false;
        });
    }
  };

  return (
    <div>
      <Modal
        open={["add_scrape","download_files",true].includes(open_modal)}
        onClose={() => dispatch(handle_open_channel_scrape_modal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="container_modal_channel_scrape"
          id="container_modal_nested_channel"
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => dispatch(handle_open_channel_scrape_modal(false))}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="container_modal_channel_scrape_desc">
            <Formik
              initialValues={{ channel_name: "" }}
              validate={(values) => {
                const errors = {};
                // eslint-disable-next-line no-useless-escape
                const channel_regex = new RegExp(
                  "^https://t.me/[a-zA-Z0-9_]+$",
                  "i",
                );
                let clone_channel_name = values.channel_name;
                clone_channel_name = clone_channel_name.replace(/\/$/, "");
                if (values.channel_name.length === 0) {
                  errors.channel_name = "";
                } else if (channel_regex.test(clone_channel_name) === false) {
                  errors.channel_name =
                    "please follow format (https://t.me/username)";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  if (!selected_category) return;
                  let clone_channel_name = values.channel_name;
                  clone_channel_name = clone_channel_name.replace(/\/$/, "");
                  handle_add_channel_request(
                    clone_channel_name,
                    selected_category,
                    open_modal,
                  );
                });
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Stack direction="row" spacing={1}>
                    <div className="nested_input_div">
                      <label htmlFor="nested_channel_name">
                        Channels/Groups
                      </label>
                      <input
                        name="channel_name"
                        className="nested_channel_name"
                        id="nested_channel_name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.channel_name}
                        type="search"
                        placeholder="e.g. https://t.me/username"
                        autoComplete="off"
                      />
                    </div>
                    <div className="nested_input_div">
                      <label htmlFor="nested_channel_name">
                        select category
                      </label>
                      <div style={{ position: "relative" }}>
                        <p
                          className="channel_scrape_drowpdown_title"
                          onClick={() =>
                            set_open_category_dropdown((prev) => !prev)
                          }
                        >
                          {selected_category
                            ? rename_all_categories_name(selected_category)
                            : "select your category"}
                        </p>
                        {!errors.channel_name &&
                        touched.channel_name &&
                        !selected_category ? (
                          <p className="scrape_channel_name_validation">
                            Please select a category
                          </p>
                        ) : null}
                        {open_category_dropdown ? (
                          <ClickAwayListener
                            onClickAway={() =>
                              set_open_category_dropdown(false)
                            }
                          >
                            <div className="channel_scrape_category_list scroll-custom">
                              {options_post_category.map((item, index) => {
                                return (
                                  <p
                                    key={index}
                                    className="channel_scrape_category_item"
                                    onClick={() => {
                                      set_selected_category(item);
                                      set_open_category_dropdown(false);
                                    }}
                                  >
                                    {rename_all_categories_name(item)}
                                  </p>
                                );
                              })}
                            </div>
                          </ClickAwayListener>
                        ) : null}
                      </div>
                    </div>
                  </Stack>
                  {errors.channel_name && touched.channel_name ? (
                    <p className="scrape_channel_name_validation">
                      {errors.channel_name}
                    </p>
                  ) : null}

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button_global_style"
                    style={{ marginTop: "3rem" }}
                  >
                    Add
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
ChannelScrapeRequest.propTypes = {
  open_nested_channel_modal: PropTypes.bool,
  set_open_nested_channel_modal: PropTypes.func,
};

export default ChannelScrapeRequest;
