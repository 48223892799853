import React, { useState, useEffect, createContext, useCallback } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import CompanyStats from "./CompanyStats";
import { handle_time_format } from "./companyglobalfunc";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_determine_graph_or_table_data } from "./companyglobalfunc";
import { isObject } from "../../../utils/Globals/global_functions";
import { chart_static_keys_value } from "../../../utils/Globals/global_variables";
const chart_filter_context = createContext();
function CompanyStatsFunc() {
  const token = handle_session_get("Token");
  const [dashbord_version, set_dashboard_version] = useState("");
  const [product_stats, set_product_stats] = useState({});
  const [domain_stats, set_domain_stats] = useState({});
  const [tag_stats, set_tag_stats] = useState({});
  const [channel_stats, set_channel_stats] = useState({});
  const [alert_stats, set_alert_stats] = useState({});
  const [tag_name_obj, set_tag_name_obj] = useState({});
  const [product_status, set_product_status] = useState({
    error: "",
    error_status: "",
    loading: false,
  });
  const [domain_status, set_domain_status] = useState({
    error: "",
    error_status: "",
    loading: false,
  });
  const [tag_status, set_tag_status] = useState({
    error: "",
    error_status: "",
    loading: false,
  });
  const [channel_status, set_channel_status] = useState({
    error: "",
    error_status: "",
    loading: false,
  });
  const [alert_status, set_alert_status] = useState({
    error: "",
    error_status: "",
    loading: false,
  });
  const [company_pref_data, set_company_pref_data] = useState([]);
  const [dashboard_chart_list_array, set_dashboard_chart_list_array] = useState(
    [],
  );
  const [minimized_chart_list, set_minimized_chart_list] = useState([]);

  const user_name_session = localStorage.getItem("admin_status");
  useEffect(() => {
    handle_determine_user_dashboard_access();
    if (
      user_name_session === "company_admin" ||
      user_name_session === "super_admin"
    ) {
      get_company_preference();
    }
    setTimeout(() => localStorage.setItem("company_url_status", "enabled"));
  }, []);
  const handle_determine_user_dashboard_access = () => {
    let isSubmitBlocked = false;
    set_alert_status((prev) => ({ ...prev, loading: true }));
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .get("https://dev.recordedtelegram.com/dashboard_access", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const response = res.data;
          set_alert_status((prev) => ({ ...prev, loading: false, error: "" }));
          set_dashboard_version(response.default_version);
          if (response.has_dashboard_access === true) {
            if (response.default_version === "version_2") {
              const dashboard_chart_list = [...response.enabled_dashboards];
              const priorityOrder = [
                "file",
                "channel",
                "keyword",
                "tag",
                "stealer log",
              ];
              dashboard_chart_list.sort((a, b) => {
                return (
                  priorityOrder.indexOf(a.dashboard_type) -
                  priorityOrder.indexOf(b.dashboard_type)
                );
              });
              const disabled_dashboards = response?.disabled_dashboards ?? [];
              set_dashboard_chart_list_array(dashboard_chart_list);
              set_minimized_chart_list(disabled_dashboards);
              dashboard_chart_list.forEach((item) =>
                get_individual_chart_alert_stats(item),
              );

              // Use Promise.all to handle all promises
              // Promise.allSettled(promises)
              // .then((results) => {
              //   results.forEach((result, index) => {
              //     if (result.status === "fulfilled") {
              //     } else {
              //       console.error(`Request ${index + 1} failed:`, result.reason);
              //     }
              //   });
              // });
            } else {
              get_chart_data_product();
              get_chart_data_domain();
              get_chart_data_tag();
              get_chart_data_channel();
            }
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_alert_status((prev) => ({
            ...prev,
            loading: false,
            error: err.response.data.errormsg,
          }));
          isSubmitBlocked = false;
        });
    }
  };

  // Get Product chart data
  const get_chart_data_product = () => {
    let isSubmitBlocked = false;
    set_product_status((prev) => ({
      ...prev,
      loading: true,
      error_status: false,
      error: "",
    }));
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .get("https://dev.recordedtelegram.com/user_pref_stats", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          isSubmitBlocked = false;
          const all_product_pref = res.data.statistics;
          const filter_obj = {};
          for (const pref in all_product_pref) {
            const all_product_res = all_product_pref[pref];
            for (const key in all_product_res) {
              const categories_obj = Object.fromEntries(
                Object.entries(all_product_res[key]),
              );
              const restructuredData = categories_obj[
                Object.keys(categories_obj)[0]
              ].map((item, index) => {
                const newObj = {
                  name: handle_time_format(
                    categories_obj[Object.keys(categories_obj)[0]][index].date,
                  ),
                };

                for (const val in categories_obj) {
                  newObj[val] = categories_obj[val][index].value;
                }
                return newObj;
              });

              const categories_channel_obj = {};
              for (const val in all_product_res[key]) {
                categories_channel_obj[val] = all_product_res[key][val].map(
                  (item) => item.channel,
                );
              }
              filter_obj[key] = {
                data: restructuredData,
                categories_channel: categories_channel_obj,
                show_channel: false,
                show_message: false,
                channel_details: [],
                message_details: {
                  hovered_name: "",
                  hovered_messages: [],
                },
                hovered_category: "",
                pref_type: pref,
                loading_chart: false,
                error: "",
              };
            }
          }
          set_product_stats(() => filter_obj);
          set_product_status((prev) => ({
            ...prev,
            loading: false,
            error_status: false,
          }));
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          isSubmitBlocked = false;
          set_product_status((prev) => ({
            ...prev,
            loading: false,
            error_status: true,
            error: err.response.data.errormsg,
          }));
        });
    }
  };

  const filter_domain_array = (categories_obj) => {
    try {
      const tagsLength = categories_obj.tags_stats.length;
      const stealerLength = categories_obj.stealer_stats.length;

      let longerArray, shorterArray;

      if (tagsLength > stealerLength) {
        longerArray = categories_obj.tags_stats;
        shorterArray = categories_obj.stealer_stats;
      } else {
        longerArray = categories_obj.stealer_stats;
        shorterArray = categories_obj.tags_stats;
      }
      for (const item of longerArray) {
        const matchingItem = shorterArray.find(
          (shortItem) => shortItem.key_as_string === item.key_as_string,
        );

        if (!matchingItem) {
          shorterArray.push({
            key_as_string: item.key_as_string,
            key: 0,
            doc_count: 0,
          });
        }
      }
      for (const key in categories_obj) {
        categories_obj[key] = categories_obj[key].sort(
          (a, b) => new Date(a.key_as_string) - new Date(b.key_as_string),
        );
      }
      return categories_obj;
    } catch {
      return categories_obj;
    }
  };
  // Get Product chart data
  const get_chart_data_domain = () => {
    let isSubmitBlockedDomain = false;
    set_domain_status((prev) => ({
      ...prev,
      loading: true,
      error_status: false,
      error: "",
    }));
    if (!isSubmitBlockedDomain) {
      isSubmitBlockedDomain = true;
      axios
        .get("https://dev.recordedtelegram.com/user_domain_stats", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          isSubmitBlockedDomain = false;
          const all_domain_pref = res.data.statistics;
          const filter_obj = {};
          for (const pref in all_domain_pref) {
            const all_domain_res = all_domain_pref[pref];
            for (const key in all_domain_res) {
              const categories_obj_unfiltered = Object.fromEntries(
                Object.entries(all_domain_res[key]).slice(0, 5),
              );
              const categories_obj = filter_domain_array(
                categories_obj_unfiltered,
              );
              const filter_array =
                categories_obj[Object.keys(categories_obj)[0]];
              const restructuredData = filter_array.map((item, index) => {
                const newObj = {
                  name: handle_time_format(
                    categories_obj[Object.keys(categories_obj)[0]][index]
                      .key_as_string,
                  ),
                };
                for (const val in categories_obj) {
                  newObj[val] = categories_obj[val][index].doc_count;
                }
                return newObj;
              });
              filter_obj[key] = {
                data: restructuredData,
                pref_type: pref,
                loading_chart: false,
                error: "",
              };
            }
          }

          set_domain_stats(() => filter_obj);
          set_domain_status((prev) => ({
            ...prev,
            loading: false,
            error_status: false,
          }));
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          isSubmitBlockedDomain = false;
          set_domain_status((prev) => ({
            ...prev,
            loading: false,
            error_status: true,
            error: err.response.data.errormsg,
          }));
        });
    }
  };

  // Get Tags chart data
  const get_chart_data_tag = () => {
    let isSubmitBlockedTag = false;
    set_tag_status((prev) => ({
      ...prev,
      loading: true,
      error_status: false,
      error: "",
    }));
    if (!isSubmitBlockedTag) {
      isSubmitBlockedTag = true;
      axios
        .get("https://dev.recordedtelegram.com/user_tag_stats", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          isSubmitBlockedTag = false;
          const all_tag_pref = res.data.statistics;
          const tag_name = res.data.tag_preference;
          const filter_obj = {};
          for (const pref in all_tag_pref) {
            const all_tag_res = all_tag_pref[pref];
            for (const key in all_tag_res) {
              if (Object.keys(all_tag_res[key]).length < 1) {
                // filter_obj[key] = {
                //   data: handle_static_chart_data_tag(),
                //   categories_channel: [],
                //   show_channel: false,
                //   show_message: false,
                //   channel_details: {},
                //   message_details: {
                //     hovered_name: "",
                //     hovered_messages: [],
                //   },
                //   hovered_category: "",
                //   pref_type: pref,
                //   loading_chart: false,
                //   error: "",
                // };
                continue;
              }
              const categories_obj = Object.fromEntries(
                Object.entries(all_tag_res[key]).slice(0, 5),
              );
              const filter_array =
                categories_obj[Object.keys(categories_obj)[0]];

              const restructuredData = filter_array.map((item, index) => {
                const newObj = {
                  name: handle_time_format(item.date),
                };
                for (const val in categories_obj) {
                  newObj[val] = categories_obj[val][index].value;
                }
                return newObj;
              });

              const categories_channel_arr = filter_array.map((item, index) => {
                const newObj = {};
                for (const val in categories_obj) {
                  newObj[val] = {
                    channel_name: val,
                    total_messages: categories_obj[val][index].messages.length,
                    messages: categories_obj[val][index].messages,
                    date: item.date,
                  };
                }
                return newObj;
              });

              filter_obj[key] = {
                data: restructuredData,
                categories_channel: categories_channel_arr,
                show_channel: false,
                show_message: false,
                channel_details: {},
                message_details: {
                  hovered_name: "",
                  hovered_messages: [],
                },
                hovered_category: "",
                pref_type: pref,
                loading_chart: false,
                error: "",
              };
            }
          }
          // Set the tag name
          if (tag_name.length > 0) {
            const filter_tag_names = tag_name.map((item) => {
              const [key, value] = Object.entries(item)[0];
              return [value, key];
            });
            const tags_obj = Object.fromEntries(filter_tag_names);
            set_tag_name_obj(tags_obj);
          }
          set_tag_stats(() => filter_obj);
          set_tag_status((prev) => ({
            ...prev,
            loading: false,
            error_status: false,
          }));
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          isSubmitBlockedTag = false;
          set_tag_status((prev) => ({
            ...prev,
            loading: false,
            error_status: true,
            error: err.response.data.errormsg,
          }));
        });
    }
  };

  const get_chart_data_channel = () => {
    let isSubmitBlockedChannel = false;
    set_channel_status((prev) => ({
      ...prev,
      loading: true,
      error_status: false,
      error: "",
    }));
    if (!isSubmitBlockedChannel) {
      isSubmitBlockedChannel = true;
      axios
        .get("https://dev.recordedtelegram.com/user_channel_stats", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          isSubmitBlockedChannel = false;
          const all_channel_pref = res.data.statistics;
          const filter_obj = {};
          for (const pref in all_channel_pref) {
            const all_channel_res = all_channel_pref[pref];
            for (const key in all_channel_res) {
              if (Object.keys(all_channel_res[key]).length < 1) {
                continue;
              }
              const filter_array = all_channel_res[key];
              const categories_channel_arr = [];
              const restructuredData = filter_array.map((item) => {
                const newObj = {
                  name: handle_time_format(item.date),
                  [key]: item.value,
                };
                categories_channel_arr.push(item);
                return newObj;
              });
              filter_obj[key] = {
                data: restructuredData,
                categories_channel: categories_channel_arr,
                show_channel: false,
                show_message: false,
                channel_details: {},
                message_details: {
                  hovered_name: "",
                  hovered_messages: [],
                },
                hovered_category: "",
                pref_type: pref,
                loading_chart: false,
                error: "",
              };
            }
          }
          set_channel_stats(filter_obj);
          set_channel_status((prev) => ({
            ...prev,
            loading: false,
            error_status: false,
          }));
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          isSubmitBlockedChannel = false;
          set_channel_status((prev) => ({
            ...prev,
            loading: false,
            error_status: true,
            error: err.response.data.errormsg,
          }));
        });
    }
  };
  const get_individual_chart_alert_stats = useCallback(
    (chart_item) => {
      let isSubmitBlocked = false;
      const type_chart = chart_item["dashboard_type"];
      const stats_name = chart_item["id"];
      try {
        set_alert_stats((prev) => ({
          ...prev,
          [type_chart]: {
            ...prev[type_chart],
            [stats_name]:
              type_chart === "keyword" || type_chart === "tag"
                ? {
                    alert_id: chart_item["id"],
                    keyword: chart_item["keyword"],
                    ...chart_static_keys_value[type_chart],
                  }
                : {
                    alert_id: chart_item["id"],
                    keyword: chart_item["keyword"],
                    ...chart_static_keys_value["combined"],
                  },
          },
        }));
      } catch (error) {
        set_alert_stats((prev) => prev);
      }

      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .get(
            `https://dev.recordedtelegram.com/user_dashboard/${chart_item["id"]}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          )
          .then((res) => {
            isSubmitBlocked = false;
            const all_product_pref = res.data.data;
            if (!all_product_pref) throw "No data to display";
            const chart_res =
              all_product_pref[Object.keys(all_product_pref)[0]];
            if (
              isObject(chart_res) === false ||
              (isObject(chart_res) === true &&
                Object.keys(chart_res).length === 0)
            ) {
              throw "No data to display";
            }
            const keyword_res = Object.keys(all_product_pref)[0];
            const type = chart_item["dashboard_type"];
            const all_product_res =
              all_product_pref?.[Object.keys(all_product_pref)[0]];
            if (!all_product_res) return;
            const filter_obj = {};
            try {
              if (["keyword", "file"].includes(type)) {
                const restructuredData = handle_determine_graph_or_table_data(
                  all_product_res["data"],
                  all_product_res["display_module"],
                  type,
                )["sanitize_data"];
                const categories_channel_obj =
                  handle_determine_graph_or_table_data(
                    all_product_res["data"],
                    all_product_res["display_module"],
                    type,
                  )["sanitize_channel"];

                filter_obj[chart_item["id"]] = {
                  data: restructuredData,
                  all_response_data: all_product_res["data"],
                  total_data: all_product_res?.["total_data"] ?? 0,
                  categories_channel: categories_channel_obj,
                  all_details: {
                    ...all_product_res,
                  },
                  show_channel: false,
                  show_message: false,
                  channel_details: [],
                  message_details: {
                    hovered_name: "",
                    hovered_messages: [],
                  },
                  hovered_category: "",
                  alert_id: all_product_res?.["id"],
                  graph_type: all_product_res["dashboard_filter"] ?? "None",
                  query_filter:
                    all_product_res["dashboard_filter"] ?? "default",
                  display_mode: all_product_res["display_module"],
                  pref_type:
                    all_product_res["dashboard_preference"] === "company"
                      ? "company_preference"
                      : "user_preference",
                  delete_access: all_product_res["user_can_delete"],
                  keyword: keyword_res,
                  loading_chart: false,
                  error: "",
                };
              } else if (["channel", "stealer log"].includes(type)) {
                filter_obj[chart_item["id"]] = {
                  data: all_product_res["data"],
                  all_response_data: all_product_res["data"],
                  total_data: all_product_res?.["total_data"] ?? 0,
                  all_details: { ...all_product_res },
                  loading_chart: false,
                  error: "",
                  alert_id: all_product_res?.["id"],
                  graph_type:
                    type === "stealer log"
                      ? all_product_res["search_mode"] ?? "email"
                      : all_product_res["dashboard_filter"],
                  query_filter:
                    all_product_res["dashboard_filter"] ?? "default",
                  display_mode: all_product_res["display_module"],
                  pref_type:
                    all_product_res["dashboard_preference"] === "company"
                      ? "company_preference"
                      : "user_preference",
                  delete_access: all_product_res["user_can_delete"],
                  keyword: keyword_res,
                };
              } else if (["tag"].includes(type)) {
                const restructuredData = handle_determine_graph_or_table_data(
                  all_product_res["data"],
                  all_product_res["display_module"],
                  "tag",
                )["sanitize_data"];
                // const categories_obj = Object.fromEntries(
                //   Object.entries(all_product_res[key]["data"]).slice(0, 5),
                // );
                const categories_channel_arr =
                  handle_determine_graph_or_table_data(
                    all_product_res["data"],
                    all_product_res["display_module"],
                    "tag",
                  )["sanitize_channel"];

                filter_obj[chart_item["id"]] = {
                  data: restructuredData,
                  all_response_data: all_product_res["data"],
                  total_data: all_product_res?.["total_data"] ?? 0,
                  all_details: {
                    ...all_product_res,
                  },
                  categories_channel: categories_channel_arr,
                  show_channel: false,
                  show_message: false,
                  channel_details: {},
                  message_details: {
                    hovered_name: "",
                    hovered_messages: [],
                  },
                  hovered_category: "",
                  alert_id: all_product_res?.["id"],
                  graph_type: all_product_res["search_mode"] ?? "email",
                  query_filter:
                    all_product_res["dashboard_filter"] ?? "default",
                  display_mode: all_product_res["display_module"],
                  pref_type:
                    all_product_res["dashboard_preference"] === "company"
                      ? "company_preference"
                      : "user_preference",
                  delete_access: all_product_res["user_can_delete"],
                  keyword: keyword_res,
                  loading_chart: false,
                  error: "",
                };
              }
              set_alert_stats((prev) => ({
                ...prev,
                [type]: {
                  ...prev[type],
                  ...filter_obj,
                },
              }));
            } catch (error) {
              // Do nothing
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              const token_expire_status = handle_token_expire(
                err.response.data,
              );
              if (token_expire_status === true) return;
            }
            set_alert_stats((prev) => ({
              ...prev,
              [type_chart]: {
                ...prev[type_chart],
                [stats_name]: {
                  ...prev[type_chart][stats_name],
                  loading_chart: false,
                  error: err.response?.data?.errormsg ?? err,
                },
              },
            }));
            isSubmitBlocked = false;
          });
      }
    },
    [alert_stats],
  );

  const get_company_preference = () => {
    axios
      .get("https://dev.recordedtelegram.com/company_preference", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_company_pref_data(res.data.data);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };

  const get_chart_data_func = {
    get_chart_data_product,
    get_chart_data_domain,
    get_chart_data_tag,
    get_chart_data_channel,
    get_company_preference,
  };
  const combined_filter_chart_obj = {
    dashboard_chart_list_array,
    set_dashboard_chart_list_array,
    minimized_chart_list,
    set_minimized_chart_list,
    get_individual_chart_alert_stats,
  };
  return (
    <div>
      <chart_filter_context.Provider value={combined_filter_chart_obj}>
        <CompanyStats
          product_stats={product_stats}
          set_product_stats={set_product_stats}
          domain_stats={domain_stats}
          set_domain_stats={set_domain_stats}
          tag_stats={tag_stats}
          set_tag_stats={set_tag_stats}
          channel_stats={channel_stats}
          set_channel_stats={set_channel_stats}
          alert_stats={alert_stats}
          set_alert_stats={set_alert_stats}
          tag_name_obj={tag_name_obj}
          product_status={product_status}
          domain_status={domain_status}
          tag_status={tag_status}
          channel_status={channel_status}
          alert_status={alert_status}
          get_chart_data_func={get_chart_data_func}
          company_pref_data={company_pref_data}
          dashbord_version={dashbord_version}
        />
        <ToastContainer />
      </chart_filter_context.Provider>
    </div>
  );
}
export default CompanyStatsFunc;
export { chart_filter_context };
