import React, { useState, useEffect, memo, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import { options_post_category } from "../../../Assets/globalfunctions";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_warning_message } from "../../../utils/ResponseMessage/response_message";
import {
  handle_error_message,
  handle_success_message,
} from "../../../utils/ResponseMessage/response_message";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Stack } from "@mui/material";
import "../../../css/Modal/modal.css";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { industry_context } from "../../../components/Admin/Userprof";
import { RollerAnimation } from "../loader/Loader";

function AddCompanyNameAdmin({
  open_modal,
  set_open_modal,
  company_modal_status,
  company_name,
  get_company_name,
}) {
  const [postCategory, setPostCategory] = useState(options_post_category);
  const [edit_status, set_edit_status] = useState("company_detail");
  const [update_company_input, set_update_company_input] = useState({
    name: "",
    ratelimit: 100,
    media_limit: 10.0,
  });
  const isAllselected =
    options_post_category.length > 0 &&
    postCategory.length === options_post_category.length;
  const handleChangePostCategory = (event) => {
    const { value } = event.target;
    if (value === "all") {
      setPostCategory(
        options_post_category.length === postCategory.length
          ? []
          : options_post_category,
      );
      return;
    }
    const list_post_category = [...postCategory];
    const index_post_category = list_post_category.indexOf(value);
    index_post_category === -1
      ? list_post_category.push(value)
      : list_post_category.splice(index_post_category, 1);
    setPostCategory(list_post_category);
  };
  useEffect(() => {
    if (
      company_modal_status === "update" ||
      company_modal_status === "update_categories"
    ) {
      set_update_company_input({
        name: company_name.company_name,
        ratelimit: company_name.ratelimit,
        media_limit: company_name.media_limit,
      });
      if (company_name.category_access === "all") {
        setPostCategory(options_post_category);
      } else {
        const filter_category = company_name.category_access;
        setPostCategory(filter_category);
      }
    }
  }, []);
  const handle_company_name_update_input = (event) => {
    const { name, value } = event.target;
    set_update_company_input((prev) => ({ ...prev, [name]: value }));
  };

  const handle_update_company_name = () => {
    if (company_modal_status === "new") {
      if (
        !update_company_input.name &&
        !update_company_input.ratelimit &&
        postCategory.length === 0
      ) {
        handle_warning_message(
          "Please insert valid company name, Ratelimt and categories",
        );
      }
    }

    const token = localStorage.getItem("Token");
    const data = {
      company_name: update_company_input.name,
      ratelimit: parseInt(update_company_input.ratelimit),
      category_access:
        handle_check_category_length(postCategory) === true
          ? "all"
          : postCategory,
      media_limit: parseFloat(update_company_input.media_limit).toFixed(2),
    };
    axios
      .post("https://dev.recordedtelegram.com/company_info", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const response_status =
          company_modal_status === "new" ? "Created" : "Updated";
        handle_warning_message(`${response_status} Successfully`);
        get_company_name();
        set_open_modal(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  return (
    <div>
      <Modal
        open={open_modal}
        onClose={() => set_open_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={
            company_modal_status === "update_categories"
              ? "company_dash_update_category_modal"
              : "company_dash_update_category_modal company_dash_update_category_modal_extend scroll-custom"
          }
        >
          <div className="modal_admin_close">
            <CloseIcon
              onClick={() => set_open_modal(false)}
              fontSize="medium"
              sx={{ color: "#eb0000 ", cursor: "pointer" }}
            />
          </div>
          {company_modal_status !== "update_categories" ? (
            <div className="company_edit_filter_box">
              <button
                className={
                  edit_status === "company_detail"
                    ? "filter_company_pref_btn_active"
                    : "filter_company_pref_btn"
                }
                onClick={() => set_edit_status("company_detail")}
              >
                Company
              </button>
              <button
                className={
                  edit_status === "industry_detail"
                    ? "filter_company_pref_btn_active"
                    : "filter_company_pref_btn"
                }
                onClick={() => set_edit_status("industry_detail")}
              >
                Industry
              </button>
            </div>
          ) : null}

          {edit_status === "company_detail" ? (
            <div>
              <h4
                className={
                  company_modal_status === "update_categories"
                    ? "modal_add_company_name_heading"
                    : "modal_add_company_name_heading_extended"
                }
              >
                {company_modal_status === "update_categories"
                  ? "Update Categories"
                  : company_modal_status === "update"
                  ? "Update company parameters"
                  : "Create New Company"}
              </h4>
              <Box
                className="company_dash_update_category_modal_content"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 2,
                  padding: "1rem 2rem",
                }}
              >
                {company_modal_status !== "update_categories" ? (
                  <>
                    <div className="modal_add_company_name_input">
                      <label htmlFor="company_name">Company Name</label>
                      <input
                        value={update_company_input.name}
                        type="text"
                        id="company_name"
                        name="name"
                        placeholder="enter company name"
                        onChange={handle_company_name_update_input}
                      />
                    </div>
                    <div className="modal_add_company_name_input">
                      <label htmlFor="ratelimit">Rate Limit</label>
                      <input
                        value={update_company_input.ratelimit}
                        type="number"
                        id="ratelimit"
                        name="ratelimit"
                        placeholder="enter rate limit"
                        onChange={handle_company_name_update_input}
                      />
                    </div>
                    <div className="modal_add_company_name_input">
                      <label htmlFor="ratelimit">Media Limit (in GB)</label>
                      <input
                        value={update_company_input.media_limit}
                        type="number"
                        id="media_limit"
                        name="media_limit"
                        placeholder="enter media limit"
                        onChange={handle_company_name_update_input}
                      />
                    </div>
                  </>
                ) : null}
                <FormControl>
                  <FormLabel className="modal_title">Category</FormLabel>
                  <Box className="company_dash_update_category_modal_label">
                    {options_post_category.length > 0
                      ? options_post_category.map((item, index) => {
                          let channel_name = "";
                          try {
                            if (item === "telegram2") {
                              channel_name = "hacking";
                            } else if (item === "extremepolitical2") {
                              channel_name = "political";
                            } else if (item === "arab") {
                              channel_name = "arabic";
                            } else {
                              channel_name = item;
                            }
                            channel_name = channel_name.replaceAll("_", " ");
                          } catch {
                            channel_name = item;
                          }
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handleChangePostCategory}
                                  checked={postCategory.includes(item)}
                                  size="smaller"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={channel_name}
                              key={index}
                            />
                          );
                        })
                      : null}
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="all"
                          onChange={handleChangePostCategory}
                          checked={isAllselected}
                          size="smaller"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="All"
                    />
                  </Box>
                </FormControl>
              </Box>
              <button
                className="update_categories_modal"
                onClick={handle_update_company_name}
              >
                {company_modal_status === "new" ? "Create New" : "Update"}
              </button>
            </div>
          ) : (
            <AddCompanyIndustrialPref company_name={company_name} />
          )}
        </Box>
      </Modal>
    </div>
  );
}
AddCompanyNameAdmin.propTypes = {
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
  company_modal_status: PropTypes.string,
  company_name: PropTypes.object,
  get_company_name: PropTypes.func,
};

const AddCompanyIndustrialPref = ({ company_name }) => {
  const [sub_domain, set_sub_domain] = useState("");
  const [sub_domain_details, set_sub_domain_details] = useState({
    loading: false,
    error: "",
    data: [],
  });

  const [add_status, set_add_status] = useState("create_new");
  const [row_id, set_row_id] = useState();
  const [all_users, set_all_users] = useState([]);
  const [users, set_users] = useState([]);
  const [industries, set_industries] = useState([]);
  const all_industries = useContext(industry_context);
  const token = handle_session_get("Token");
  useEffect(() => {
    handle_get_all_users();
    handle_get_subdomain_credential();
  }, []);

  const handle_get_all_users = () => {
    axios
      .get(
        `https://dev.recordedtelegram.com/api/clients/?company=${company_name["company_id"]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const response = res.data.data;
        set_all_users(response);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };

  const handle_get_subdomain_credential = () => {
    set_sub_domain_details((prev) => ({ ...prev, loading: true }));
    axios
      .get(
        `https://dev.recordedtelegram.com/subdomains_preference/?company_id=${company_name["company_id"]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const response = res.data.data;
        set_sub_domain_details((prev) => ({
          ...prev,
          loading: false,
          error: "",
          data: response,
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_sub_domain_details((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.errormsg,
        }));
      });
  };

  const handle_add_subdomain_credential = (
    industry_list,
    sub_domain_name,
    users_list,
    add_status,
    row_id,
  ) => {
    const data = {
      industry: industry_list,
      sub_domain: sub_domain_name,
      company_id: company_name["company_id"],
      users: users_list,
    };
    const data_update = {
      industry: industry_list,
      sub_domain: sub_domain_name,
      company_id: company_name["company_id"],
      users: users_list,
      id: row_id,
    };
    axios
      .post(
        "https://dev.recordedtelegram.com/subdomains_preference",
        add_status === "update" && row_id ? data_update : data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const response = Array.isArray(res.data.data)
          ? res.data.data[0]
          : res.data.data;
        const filter_res = sub_domain_details["data"].filter(
          (item) => item.id !== response.id,
        );
        if (add_status === "update") {
          set_sub_domain_details((prev) => ({
            ...prev,
            data: [...filter_res, response],
          }));
        } else {
          set_sub_domain_details((prev) => ({
            ...prev,
            data: [...prev.data, response],
          }));
        }

        handle_success_message(res.data.message);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  const handle_delete_subdomain_credential = (row_id) => {
    const data = {
      id: row_id,
    };
    axios
      .post(
        "https://dev.recordedtelegram.com/remove_subdomains_preference",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const filter_res = sub_domain_details["data"].filter(
          (item) => item.id !== row_id,
        );
        set_sub_domain_details((prev) => ({
          ...prev,
          data: [...filter_res],
        }));
        handle_success_message(res.data.message);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  const handleChange = (event, field) => {
    const {
      target: { value },
    } = event;
    if (field === "user") {
      set_users(typeof value === "string" ? value.split(",") : value);
    } else {
      set_industries(typeof value === "string" ? value.split(",") : value);
    }
  };

  const name_users = all_users.map((item) => item.username);
  const name_industries = all_industries.map((item) => item.name);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const filter_subdomain_parameters = () => {
    const filter_user_id = all_users
      .filter((item) => users.includes(item.username))
      .map((item) => item.userid);
    const filter_industry_id = all_industries
      .filter((item) => industries.includes(item.name))
      .map((item) => item.id);
    handle_add_subdomain_credential(
      filter_industry_id,
      sub_domain,
      filter_user_id,
      add_status,
      row_id,
    );
    handle_reset_inputs();
  };
  const handle_reset_inputs = () => {
    set_users([]);
    set_industries([]);
    set_sub_domain("");
    set_add_status("create_new");
  };

  const handle_set_data_update = (item) => {
    const filter_user = Array.isArray(item.users)
      ? item.users.length > 0
        ? item.users.map((item) => item.username)
        : "None"
      : "None";
    const filter_industry = Array.isArray(item.industry)
      ? item.industry.length > 0
        ? item.industry.map((item) => item.name)
        : "None"
      : "None";
    if (filter_user !== "None") {
      set_users(filter_user);
    } else {
      set_users([]);
    }
    if (filter_industry !== "None") {
      set_industries(filter_industry);
    } else {
      set_industries([]);
    }
    if (item.name && item.name !== "None") {
      set_sub_domain(item.name);
    } else {
      set_sub_domain("");
    }
  };

  return (
    <div>
      <h4 className="modal_add_company_name_heading_extended">
        Company sub domain Preference
      </h4>
      <Stack
        direction="row"
        style={{ width: "92%", marginTop: "-32px" }}
        justifyContent="flex-end"
      >
        <button
          className="clear_all_marked_channels_btn"
          onClick={() => {
            handle_reset_inputs();
          }}
        >
          Reset
        </button>
      </Stack>
      <Box
        className="company_dash_update_category_modal_content"
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          rowGap: 2,
          padding: "1rem",
          alignItems: "center",
        }}
      >
        <div>
          <Stack
            direction="row"
            columnGap={1}
            alignItems="baseline"
            sx={{ ml: 2 }}
          >
            <div className="modal_add_company_name_input_subdomain">
              <label htmlFor="subdomain">sub domain</label>
              <input
                value={sub_domain}
                type="text"
                id="subdomain"
                name="subdomain"
                onChange={(event) => set_sub_domain(event.target.value)}
                autoComplete="off"
              />
            </div>
            <Stack>
              <label className="company_subdomain_label" htmlFor="company_name">
                Choose user
              </label>
              <FormControl sx={{ width: 320, color: "white" }} size="small">
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // variant="standard"
                  disableUnderline
                  multiple
                  value={users}
                  onChange={(event) => handleChange(event, "user")}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={{
                    width: 320,
                    borderRadius: "30px",
                    color: "whitesmoke",
                    padding: 0,
                    border: "1px solid rgba(55, 248, 232, 0.5)",
                  }}
                >
                  {name_users.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={users.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {name_users.length === 0 ? (
                <p
                  className="selected_label_industry"
                  style={{ textAlign: "center" }}
                >
                  <span style={{ color: "#c11616", fontWeight: "bold" }}>
                    No users are added to the company.
                  </span>
                </p>
              ) : (
                <p className="selected_label_industry">
                  selected :{" "}
                  <span style={{ color: "yellow", fontWeight: "bold" }}>
                    {users.length}
                  </span>
                </p>
              )}
            </Stack>
            <Stack>
              <label className="company_subdomain_label" htmlFor="company_name">
                Choose industry
              </label>
              <FormControl sx={{ width: 320, color: "white" }} size="small">
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // variant="standard"
                  disableUnderline
                  multiple
                  value={industries}
                  onChange={(event) => handleChange(event, "industries")}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={{
                    width: 320,
                    borderRadius: "30px",
                    color: "whitesmoke",
                    padding: 0,
                    border: "1px solid rgba(55, 248, 232, 0.5)",
                  }}
                >
                  {name_industries.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={industries.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="selected_label_industry">
                selected :{" "}
                <span style={{ color: "yellow", fontWeight: "bold" }}>
                  {industries.length}
                </span>{" "}
              </p>
            </Stack>
          </Stack>
        </div>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <button
            className="update_categories_modal mt-4"
            onClick={() => filter_subdomain_parameters()}
          >
            {" "}
            Submit
          </button>
        </Stack>
        {sub_domain_details.loading === false ? (
          sub_domain_details.error || sub_domain_details.data.length === 0 ? (
            <div className="main__table no_data">
              {sub_domain_details["error"] || "No data to display"}
            </div>
          ) : (
            <div className="credentail_insertion_table_container">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>Sub domain</th>
                    <th style={{ width: "35%" }}>Users</th>
                    <th style={{ width: "35%" }}>Industries</th>
                    <th style={{ width: "10%" }}>Edit</th>
                    <th style={{ width: "10%" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {sub_domain_details["data"].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          {Array.isArray(item.users)
                            ? item.users.length > 0
                              ? item.users
                                  .map((item) => item.username)
                                  .toString()
                              : "None"
                            : "None"}
                        </td>
                        <td>
                          {Array.isArray(item.industry)
                            ? item.industry.length > 0
                              ? item.industry
                                  .map((item) => item.name)
                                  .toString()
                              : "None"
                            : "None"}
                        </td>

                        <td>
                          <button
                            className="credential_btn credential_btn_edit"
                            onClick={() => {
                              handle_set_data_update(item);
                              set_add_status("update");
                              set_row_id(item.id);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            className="credential_btn credential_btn_delete"
                            onClick={() =>
                              handle_delete_subdomain_credential(item.id)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        ) : (
          <RollerAnimation />
        )}
      </Box>
    </div>
  );
};
AddCompanyIndustrialPref.propTypes = {
  company_name: PropTypes.object,
};

export default memo(AddCompanyNameAdmin);
