import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import BreachData from "./BreachData";
import BreachJson from "../display_json/BreachJson";
import { DialogAlertBreach } from "../modalContainer/DialogAlert";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Breach/breachdata.css";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";

function BreachDataFunction({ breach_ref }) {
  const token = handle_session_get("Token");
  const [breach_data, set_breach_data] = useState([]);
  const [showTable, setShowTable] = useState(null);

  const [get_searched_data_breach, set_get_searched_data_breach] = useState();

  const [total_db_data, set_total_db_data] = useState();
  const [breach_error, set_breach_error] = useState();
  const [breach_error_status, set_breach_error_status] = useState(false);
  const [breach_rate_limit, set_breach_rate_limit] = useState();
  const [template_display, set_template_display] = useState("table");
  const [template_breach_parameter, set_template_breach_parameter] =
    useState("domain");
  const [file_id, set_file_id] = useState();

  const [open_modal_limit, set_open_modal_limit] = useState(false);

  useEffect(() => {
    breach_ref.current = get_breach_data;
  }, []);

  let isSubmitBlocked = false;
  const get_breach_data = (
    searchedData,
    breach_parameter,
    display_type_breach,
    max_result_breach,
    breached_date,
  ) => {
    if (max_result_breach >= 10000 && display_type_breach === "table") {
      set_open_modal_limit(true);
      return;
    }
    set_get_searched_data_breach(searchedData);
    set_template_display(display_type_breach);
    set_template_breach_parameter(breach_parameter);
    const data = {
      query: searchedData,
      mode: breach_parameter,
      max_result: Number(max_result_breach),
      date_availability: breached_date,
    };
    if (!isSubmitBlocked) {
      setShowTable(false);
      isSubmitBlocked = true;
      axios
        .post("https://dev.recordedtelegram.com/v2/breach_search", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // let splice_data = res.data.data.splice(-1, 1)
          set_breach_data(res.data.data);
          setShowTable(true);
          set_total_db_data(res.data.total_results);
          set_breach_rate_limit(res.data.breach_ratelimit);
          set_breach_error_status(false);
          set_file_id(res.data.file_id);
          setTimeout(() => {
            isSubmitBlocked = false;
          });
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          setShowTable(true);
          set_breach_error_status(true);
          set_breach_error(err.response.data.errormsg);
          setTimeout(() => {
            isSubmitBlocked = false;
          });
        });
    }
  };

  return (
    <div>
      {showTable ? (
        breach_error_status === true ? (
          <div className="main__table no_data">{breach_error}</div>
        ) : breach_data.length === 0 ? (
          <div className="main__table no_data">No data to display</div>
        ) : template_display === "table" ? (
          <div>
            <BreachData
              breach_data={breach_data}
              template_breach_parameter={template_breach_parameter}
              get_searched_data_breach={get_searched_data_breach}
              total_db_data={total_db_data}
              breach_rate_limit={breach_rate_limit}
              file_id={file_id}
              table_mode={"main_search_table"}
            />
          </div>
        ) : (
          <div>
            <BreachJson
              Data={breach_data}
              total_db_data={total_db_data}
              breach_limit={breach_rate_limit}
              get_searched_data_breach={get_searched_data_breach}
              file_id={file_id}
            />
          </div>
        )
      ) : (
        showTable !== null && (
          <div className="spinner">
            <ThreeCircles
              color="#28cab3"
              height={60}
              width={60}
              ariaLabel="three-circles-rotating"
            />
          </div>
        )
      )}

      <DialogAlertBreach
        open_modal_limit={open_modal_limit}
        set_open_modal_limit={set_open_modal_limit}
      />
      <ToastContainer />
    </div>
  );
}

BreachDataFunction.propTypes = {
  breach_ref: PropTypes.object,
};

export default BreachDataFunction;
