import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { GlobalStyles } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Breach/breachdata.css";
import { BreachdownloadOptions } from "../modalContainer/DownloadFileOptions";
function BreachData({
  breach_data,
  template_breach_parameter,
  get_searched_data_breach,
  total_db_data,
  breach_rate_limit,
  file_id,
  table_mode,
}) {
  const [pageSize, setPageSize] = useState(25);
  const [paginationValue, setPaginationValue] = useState(1);
  const check_None = (val) => {
    try {
      if (val) {
        return val;
      }
      return "None";
    } catch {
      return "None";
    }
  };
  const check_user_name = (val, type) => {
    try {
      if (val) {
        if (type === "username") {
          return val.username;
        }
        if (type === "name") {
          return val.full_name;
        }
        if (val.full_name) {
          return val.full_name;
        }
        return "None";
      }
      return "None";
    } catch {
      return "None";
    }
  };

  const columns_breach = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "email", headerName: "Email", flex: 2 },
    {
      field: "date",
      headerName: "Date",
      width: 110,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    { field: "database", headerName: "Database Name", width: 170 },
    {
      field: "username",
      headerName:
        template_breach_parameter === "username" ? "Username" : "Full Name",
      flex: 1,
    },
    {
      field: "password",
      headerName: "Password",
      flex: 1,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 110,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "ip_address",
      headerName: "IP Address",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "total_password",
      headerName: "Total Passwords",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "total_email",
      headerName: "Total Emails",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "total_phone",
      headerName: "Total Phones",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "total_ip",
      headerName: "Total IP Addresses",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "total_username",
      headerName: "Total Usernames",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const filter_data_breach = breach_data.map((item, index) => ({
    id: index,
    user_id: item.breach_id,
    email: check_None(item.email),
    date: check_None(item.source.date),
    database: check_None(item.source.name),
    username: check_user_name(
      item,
      template_breach_parameter === "username"
        ? "username"
        : template_breach_parameter === "name"
        ? "name"
        : "other",
    ),
    password: check_None(item.password),
    phone: check_None(item.phone_number),
    ip_address: check_None(item.ip_address),
    total_password: check_None(item.source.total_password),
    total_email: check_None(item.source.total_emails),
    total_phone: check_None(item.source.total_phone_numbers),
    total_ip: check_None(item.source.total_ips),
    total_username: check_None(item.source.total_usernames),
  }));

  const clone_data_breach = [...filter_data_breach];
  const new_state_breach = clone_data_breach.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(filter_data_breach.length / pageSize);
  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };
  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(filter_data_breach.length / newPageSize));
  };
  return (
    <>
      <div
        className={
          table_mode === "main_search_table"
            ? "main__table breach_main_table"
            : "main__table breach_main_table main__table_dashboard"
        }
      >
        <div className="total_data_number">
          <h6>
            Results shown/found:{" "}
            <span>
              {breach_data.length}/
              {breach_data.length < total_db_data
                ? total_db_data
                : breach_data.length}
            </span>
          </h6>
          <div className="total_data_number_child">
            <BreachdownloadOptions
              getSearchedData={get_searched_data_breach}
              file_id={file_id}
            />
            <h6>
              Credits remaining :{" "}
              <span className="credit_limit_colors">{breach_rate_limit}</span>
            </h6>
          </div>
        </div>
        <GlobalStyles
          styles={{
            ".MuiDataGrid-menuList": {
              backgroundColor: "#394e5a",
            },
            ".MuiMenu-list ": {
              backgroundColor: "#394e5a",
            },
            ".MuiDataGrid-menuList li": {
              color: "#dcf3ff",
            },
            ".MuiMenu-list li": {
              color: "#dcf3ff",
            },
            ".MuiPaginationItem-root": {
              color: "#9bbcd1",
              backgroundColor: "#1e2a31",
            },
            ".MuiDataGrid-filterForm": {
              backgroundColor: "#394e5a",
            },
          }}
        />
        <DataGrid
          rows={new_state_breach}
          columns={columns_breach}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          autoHeight
          columnVisibilityModel={{
            id: false,
            user_id: false,
            total_email: template_breach_parameter === "email",
            total_phone: template_breach_parameter === "phone",
            total_ip: template_breach_parameter === "ip",
            total_username: !!(
              template_breach_parameter === "name" ||
              template_breach_parameter === "username"
            ),
          }}
          disableColumnSelector
          density="comfortable"
        />
        <Pagination
          count={new_total_page}
          color="primary"
          page={paginationValue}
          onChange={handlePaginationChange}
          size="small"
        />
      </div>
      <ToastContainer />
    </>
  );
}

BreachData.propTypes = {
  breach_data: PropTypes.array,
  breach_rate_limit: PropTypes.number,
  file_id: PropTypes.string,
  get_searched_data_breach: PropTypes.string,
  template_breach_parameter: PropTypes.string,
  total_db_data: PropTypes.number,
  table_mode: PropTypes.string,
};

export default memo(BreachData);
